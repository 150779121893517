import React, { useEffect, useState } from "react";
import Aux from "../../hoc/auxiliar";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import TestDrive from "../../components/TestDrive";
import TesteDriveModel, { TestDriveFinishModel } from "../../models/TestDrive";
import Alert from "@material-ui/lab/Alert";
import { Button, Grid } from "@material-ui/core";
import {
  updateTestDrive,
  updateTestDriveFinish,
} from "../../services/testDrive";
import { formatDate } from "../../helpers/functions";
import { getTestDriveById } from "../../services/testDrive";
import { findObjectByKey } from "../../helpers/functions";
import { findRegionByState, fetchCities } from "../../helpers/location";
import {
  axisConfigurations,
  differentialRatios,
  engines,
  implementSetup,
  implementTypes,
  pbtcs,
  truckloadFactors,
  truckloadTypes,
  routeSeverity,
} from "../../components/TestDrive/config/selectsOptions";
import useStyles from "./styles";

export default function Edit(props) {
  const { state } = useLocation();
  const classes = useStyles();
  const [testDriveEdit, setTestDriveEdit] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchTestDriveData = async (data) => {
    setLoading(true);
    const response = await getTestDriveById(data.id);
    if (response.status === 200) {
      const formatedResponse = {
        ...response.data,
        axisConfiguration: findObjectByKey(
          axisConfigurations,
          response.data.axisConfiguration,
        ),
        differentialRatio: findObjectByKey(
          differentialRatios,
          response.data.differentialRatio,
        ),
        engine: findObjectByKey(engines, +response.data.engine),
        implementSetup: findObjectByKey(
          implementSetup,
          response.data.implementSetup,
        ),
        implementType: findObjectByKey(
          implementTypes,
          response.data.implementType,
        ),
        pbtc: findObjectByKey(pbtcs, response.data.pbtc),
        routes: response.data.routes
          ? response.data.routes.map((route) => ({
              routeDestinationId: route.routeDestinationId,
              routeOriginId: route.routeOriginId,
              originLabel: `${route.routeOriginName} - ${
                findObjectByKey(
                  fetchCities(
                    findRegionByState(
                      route.routeOriginId.toString().slice(0, 2),
                    ).value,
                  ),
                  route.routeOriginId.toString().slice(0, 2),
                ).label
              }`,
              destinyLabel: `${route.routeDestinationName} - ${
                findObjectByKey(
                  fetchCities(
                    findRegionByState(
                      route.routeDestinationId.toString().slice(0, 2),
                    ).value,
                  ),
                  route.routeDestinationId.toString().slice(0, 2),
                ).label
              }`,
            }))
          : [],
        routeSeverity: findObjectByKey(
          routeSeverity,
          response.data.routeSeverity,
        ),
        truckloadFactor: findObjectByKey(
          truckloadFactors,
          response.data.truckloadFactor,
        ),
        truckloadType: findObjectByKey(
          truckloadTypes,
          response.data.truckloadType,
        ),
      };
      setTestDriveEdit(formatedResponse);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state) {
      fetchTestDriveData(state.company);
    }
  }, [state]);

  const onSubmit = async (data) => {
    const testDriveModel = new TesteDriveModel(
      data.id,
      data.axisConfiguration.value,
      data.chassisNumber.value,
      data.company.value,
      +data.consumptionAverage,
      data.dealership.value,
      data.differentialRatio.value,
      data.engine.value,
      formatDate(data.finishDate),
      data.implementSetup.value,
      data.implementType.value,
      +data.maxSpeed,
      data.pbtc.value,
      data.routes.map((route) => ({
        routeOriginId: route.routeOriginId.value,
        routeDestinationId: route.routeDestinationId.value,
      })),
      formatDate(data.startDate),
      data.truckloadFactor.value,
      data.truckloadType.value,
    );

    const response = await updateTestDrive(testDriveModel);

    if (response.status === 200 || response.status === 201) {
      toast.success("Empresa atualizada com sucesso");
      props.history.push("/app/testdrives/");
    } else {
      toast.error("Erro ao criar Concessionária");
    }
  };

  const onSubmitFinishForm = async (data) => {
    const testDriveFinishModel = new TestDriveFinishModel(
      data.id,
      data.achivedGoal === "n" ? false : true,
      data.becomeSale === "n" ? false : true,
      +data.buyIntentionLevel,
      +data.consumptionAverage,
      data.customerFeedback,
      data.dealershipNotes,
      formatDate(data.testDrivefinishdate),
      +data.finalScore,
      +data.satisfactionLevel,
      +data.totalDistance,
    );
    console.log(testDriveFinishModel);
    const response = await updateTestDriveFinish(testDriveFinishModel);

    if (response.status === 200 || response.status === 201) {
      toast.success("Dados complementares atualizados com sucesso");
    } else {
      toast.error("Erro ao atualizar dados complementares");
    }
  };

  if ((loading || !testDriveEdit) && state) {
    return "...loading";
  }

  return (
    <Aux>
      <PageTitle title="Editando Test Drive" />
      {state ? (
        <TestDrive
          key={testDriveEdit.id}
          editData={testDriveEdit}
          onSubmit={onSubmit}
          onSubmitFinishForm={onSubmitFinishForm}
        />
      ) : (
        <Grid container>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Alert severity="error">
              Erro ao carregar dados para editar, selecione novamente!
            </Alert>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        alignItems="center"
        justify="flex-end"
        className={classes.backButton}
      >
        <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
          <Button
            title="Adicionar"
            variant="contained"
            fullWidth
            color="secondary"
            onClick={props.history.goBack}
          >
            VOLTAR
          </Button>
        </Grid>
      </Grid>
    </Aux>
  );
}
