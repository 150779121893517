import { format } from 'date-fns'

export const formatDate = (date) => {
  const brDate = format(date, 'dd/MM/yyyy')
  const splitedDate = brDate.split("/");
  const formatedDate = `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`;
  return formatedDate;
};

export const orderByName = (data) => {
  if (!data) {
    return;
  }
  return data.sort(function (a, b) {
    if (a.label > b.label) {
      return 1;
    }
    if (a.label < b.label) {
      return -1;
    }
    return 0;
  });
};

export const chassiType = (chassiNumber) => {
  switch (chassiNumber) {
    case "1":
      return "MB202020";
    case "2":
      return "TT303030";
    default:
      return "Invalid Chassi";
  }
};

export const findObjectByKey = (array, value) => {
  for (var i = 0; i < array.length; i++) {
    if (array[i].value == value || array[i].label == value) {
      return array[i];
    }
  }
  return null;
}
