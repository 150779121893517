import api from './api';

const login = async (email, password) => {
  try {
    const resp = await api.post('/login', { email: email, password: password });
    const { ok, status, data } = resp;

    if (!ok && status !== 200) {
      throw new Error('Unauthorized');
    }
    
    return data;
  } catch (err) {
    console.error(err);
  }
};

const me = async () => {
  try {
    const resp = await api.get('/me');
    return resp.data;
  } catch (err) {
    console.error(err);
  }
};

export default {
  login,
  me,
};
