import React from "react";
import TesteDriveModel from "../../models/TestDrive";
import TestDrive from "../../components/TestDrive";
import { createTestDrive } from "../../services/testDrive";
import { formatDate } from "../../helpers/functions";
import { toast } from "react-toastify";

export default function Create(props) {
  const onSubmit = async (data) => {
    const testDriveModel = new TesteDriveModel(
      data.id,
      data.axisConfiguration.value,
      data.chassisNumber.value,
      data.company.value,
      +data.consumptionAverage,
      data.dealership.value,
      data.differentialRatio.value,
      data.engine.value,
      formatDate(data.finishDate),
      data.implementSetup.value,
      data.implementType.value,
      +data.maxSpeed,
      data.pbtc.value,
      data.routes.map((route) => ({
        routeOriginId: route.routeOriginId.value,
        routeDestinationId: route.routeDestinationId.value,
      })),
      formatDate(data.startDate),
      data.truckloadFactor.value,
      data.truckloadType.value,
    );
    const response = await createTestDrive(testDriveModel);
    if (response.status === 200 || response.status === 201) {
      toast.success("Test Drive criado com sucesso");
      props.history.push("/app/testdrives/");
    } else {
      toast.error("Erro ao criar Test Drive");
    }

  };
  return <TestDrive onSubmit={onSubmit} />;
}
