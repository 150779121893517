export default class Company {
  constructor(id = "", city, cnpj, email1, email2, name, fleet, phone1, phone2, testDriveResponsible1, testDriveResponsible2) {
    this.id = id;
    this.city = +city;
    this.cnpj = cnpj;
    this.email1 = email1;
    this.email2 = email2;
    this.fleet = fleet;
    this.name = name;
    this.phone1 = phone1;
    this.phone2 = phone2;
    this.testDriveResponsible1 = testDriveResponsible1;
    this.testDriveResponsible2 = testDriveResponsible2;
  }
}
