import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
import Aux from '../../hoc/auxiliar'

//icons
// import {
//   mdiFacebook as FacebookIcon,
//   mdiTwitter as TwitterIcon,
//   mdiGithub as GithubIcon,
// } from "@mdi/js";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import {
  List as CompanyList,
  Edit as CompanyEdit,
  Create as CompanyCreate,
} from "../../pages/company";
import {
  List as DealershipList,
  Edit as DealershipEdit,
  Create as DealershipCreate,
} from "../../pages/dealership";
import {
  List as TestDriveList,
  Create as TestDriveCreate,
  Edit as TestDriveEdit,
} from "../../pages/testDrive";
import AddUser from '../../pages/user/addUser'
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <Aux>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/dashboard" component={Dashboard} />

            <Route path="/app/testdrives" exact component={TestDriveList} />
            <Route
              path="/app/testdrives/new-test-drive"
              component={TestDriveCreate}
            />
            <Route
              path="/app/testdrives/edit"
              component={TestDriveEdit}
            />
            <Route path="/app/companies" exact component={CompanyList} />
            <Route
              path="/app/companies/new-company"
              component={CompanyCreate}
            />
            <Route path="/app/companies/edit" component={CompanyEdit} />
            <Route path="/app/dealerships" exact component={DealershipList} />
            <Route
              path="/app/dealerships/new-dealership"
              component={DealershipCreate}
            />
            <Route path="/app/dealerships/edit" component={DealershipEdit} />
            <Route path="/app/tables" component={Tables} />
            <Route path="/app/notifications" component={Notifications} />
            <Route
              exact
              path="/app/ui"
              render={() => <Redirect to="/app/ui/icons" />}
            />

            <Route path="/app/add-user" component={AddUser} />


            <Route path="/app/ui/maps" component={Maps} />
            <Route path="/app/ui/icons" component={Icons} />
            <Route path="/app/ui/charts" component={Charts} />
          </Switch>
        </div>
      </Aux>
    </div>
  );
}

export default withRouter(Layout);
