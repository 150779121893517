import api from "./api";

export const getTestDriveById = async (id) => {
  try {
    const response = await api.get(`/v1/testdrive/${id}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getAllTestDrive = async () => {
  try {
    const response = await api.get("/v1/testdrive");
    return response;
  } catch (err) {
    return err;
  }
};

export const createTestDrive = async (testDriveData) => {
  try {
    const resp = await api.post("/v1/testdrive", testDriveData);

    return resp;
  } catch (err) {
    return err;
  }
};

export const updateTestDrive = async (testDriveData) => {
  try {
    const resp = await api.put(
      `/v1/testdrive/${testDriveData.id}`,
      testDriveData,
    );

    return resp;
  } catch (err) {
    throw err;
  }
};

export const updateTestDriveFinish = async (testDriveFinishData) => {
  try {
    const resp = await api.put(
      `/v1/testdrive/${testDriveFinishData.id}/finish`,
      testDriveFinishData,
    );

    return resp;
  } catch (err) {
    return err;
  }
};

export const deleteTestDrive = async (id) => {
  try {
    const resp = await api.delete(`/v1/testdrive/${id}`);
    return resp;
  } catch (err) {
    throw err;
  }
};
