import React from 'react'
import TextField from "@material-ui/core/TextField";
import { useController, useFormContext } from "react-hook-form";

const Input = ({ name, control, readOnly, ...rest }) => {
  const { control: contextControl } = useFormContext()
  const {
    field: { ref, ...inputProps }
  } = useController({
    name,
    control: control ?? contextControl
  });

  return <TextField {...inputProps} inputRef={ref} {...rest} InputProps={{ readOnly }} />;
}

export default Input