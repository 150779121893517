import * as yup from "yup";

export const mainSchema = yup.object().shape({
  axisConfiguration: yup.object({ value: yup.number(), label: yup.string() }).required('Selecione uma opção').nullable(),
  routes: yup.array().of(yup.object()).required('Selecione no mínimo uma rota'),
  chassisNumber: yup.object({ value: yup.number(), label: yup.string() }).required('Selecione uma opção').nullable(),
  company: yup.object({ value: yup.number(), label: yup.string() }).required('Selecione uma opção').nullable(),
  consumptionAverage: yup.string().required('Campo obrigatório'),
  dealership: yup.object({ value: yup.number(), label: yup.string() }).required('Selecione uma opção').nullable(),
  // destinyState: yup.object({ value: yup.number(), label: yup.string() }).required('Selecione uma opção').nullable(),
  // destinyRegion: yup.object({ value: yup.string(), label: yup.string() }).required('Selecione uma opção').nullable(),
  differentialRatio: yup.object({ value: yup.number(), label: yup.string() }).required('Selecione uma opção').nullable(),
  engine: yup.object({ value: yup.number(), label: yup.string() }).required('Selecione uma opção').nullable(),
  implementType: yup.object({ value: yup.number(), label: yup.string() }).required('Selecione uma opção').nullable(),
  maxSpeed: yup.string().max(3, 'Limite máximo de caracteres excedido').required('Campo obrigatório'),
  // originState: yup.object({ value: yup.number(), label: yup.string() }).required('Selecione uma opção').nullable(),
  // originRegion: yup.object({ value: yup.string(), label: yup.string() }).required('Selecione uma opção').nullable(),
  pbtc: yup.object({ value: yup.number(), label: yup.string() }).required('Selecione uma opção').nullable(),
  routeDestinationId: yup.object().when("routes", {
    is: val => !val || val.length === 0 ? true : false,
    then: yup.object({ value: yup.number(), label: yup.string() }).required('Selecione uma opção').nullable(),
    otherwise: yup.object()
  }),
  routeOriginId: yup.object().when("routes", {
    is: val => !val || val.length === 0 ? true : false,
    then: yup.object({ value: yup.number(), label: yup.string() }).required('Selecione uma opção').nullable(),
    otherwise: yup.object().nullable()
  }),
  routeSeverity: yup.object({ value: yup.number(), label: yup.string() }).required('Selecione uma opção').nullable(),
  truckloadFactor: yup.object({ value: yup.number(), label: yup.string() }).required('Selecione uma opção').nullable(),
  truckloadType: yup.object({ value: yup.number(), label: yup.string() }).required('Selecione uma opção').nullable(),
})

export const secondarySchema = yup.object().shape({
  achievedGoal: yup.string().required('Campo obrigatório').nullable(),
  becomeSale: yup.string().required('Campo obrigatório').nullable(),
  buyIntentionLevel: yup.string().required('Campo obrigatório').nullable(),
  satisfactionLevel: yup.string().required('Campo obrigatório').nullable(),
  totalDistance: yup.string().required('Campo obrigatório').nullable(),

})