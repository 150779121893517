import React from "react";
import Dealership from "../../components/Dealership";
import PageTitle from "../../components/PageTitle/PageTitle";
import Aux from "../../hoc/auxiliar";
import DealershipModel from "../../models/Dealership";
import { createDealership } from "../../services/dealership";
import { toast } from "react-toastify";

export default function Create(props) {
  const onSubmit = async (data) => {
    const dealershipModel = new DealershipModel(
      data.id,
      data.city.value,
      data.dealershipName,
      data.users
    );
    const response = await createDealership(dealershipModel);
    if (response.status === 200 || response.status === 201) {
      props.history.push("/app/dealerships/");
      toast.success("Concessionária criada com sucesso");
    } else {
      toast.error("Erro ao criar Concessionária");
      props.history.push("/app/dealerships/");
    }
  };

  return (
    <Aux>
      <PageTitle title="Cadastrar Concessionária" />
      <Dealership onSubmit={onSubmit} onCancel={props.history.goBack} />
    </Aux>
  );
}
