import image2 from "../../../images/truckImplements/image2.png";
import image3 from "../../../images/truckImplements/image3.png";
import image4 from "../../../images/truckImplements/image4.png";
import image5 from "../../../images/truckImplements/image5.png";
import image6 from "../../../images/truckImplements/image6.png";
import image7 from "../../../images/truckImplements/image7.png";
import image8 from "../../../images/truckImplements/image8.png";
import image9 from "../../../images/truckImplements/image9.png";
import image10 from "../../../images/truckImplements/image10.png";
import image11 from "../../../images/truckImplements/image11.png";
import image12 from "../../../images/truckImplements/image12.png";
import image13 from "../../../images/truckImplements/image13.png";

export const axisConfigurations = [
  { value: 1, label: "4x2" },
  { value: 2, label: "6x2" },
  { value: 3, label: "6x4" },
];

export const chassis = [
  { value: 1, label: "MB202020" },
  { value: 2, label: "TT303030" },
];

export const differentialRatios = [
  { value: 1, label: "2,64:1" },
  { value: 2, label: "2,80:1" },
  { value: 3, label: "2,85:1" },
  { value: 4, label: "3,07:1" },
  { value: 5, label: "3,08:1" },
  { value: 6, label: "3,40:1" },
  { value: 7, label: "3,42:1" },
];

export const engines = [
  { value: 3, label: "410 cv" },
  { value: 1, label: "480 cv" },
  { value: 2, label: "530 cv" },
];

export const implementSetup = [
  { value: 1, label: "Baú geral" },
  { value: 2, label: "Camara fria" },
  { value: 3, label: "Basculante" },
  { value: 4, label: "Sider" },
  { value: 5, label: "Graneleiro" },
  { value: 6, label: "Tanque" },
  { value: 7, label: "Cegonha" },
  { value: 8, label: "Silo" },
  { value: 9, label: "Porta container" },
  { value: 10, label: "Carga seca" },
  { value: 11, label: "Porta bobina" },
  { value: 12, label: "Porta bebidas" },
  { value: 13, label: "Semi Reboque para transporte de cavaco de madeira" },
  { value: 14, label: "Semi Reboque para transporte de bagaço de cana" },
  { value: 15, label: "Prancha carrega tudo" },
  { value: 16, label: "Prancha multi uso" },
  { value: 17, label: "Florestal " },
  { value: 18, label: "Canavieiro " },
];

export const implementTypes = [
  {
    value: 17,
    label: "Semi reboque + reboque 3 eixos",
    image: image2,
  },
  {
    value: 15,
    label: "Semi reboque + reboque 4 eixos",
    image: image3,
  },
  { value: 16, label: "Semi reboque 1 eixo", image: image4 },
  {
    value: 3,
    label: "Semi Reboque 2 eixos juntos",
    image: image5,
  },
  {
    value: 4,
    label: "Semi Reboque 3 eixos juntos",
    image: image12,
  },
  {
    value: 2,
    label: "Semi Reboque 2 eixos espaçados",
    image: image6,
  },
  {
    value: 5,
    label: "Semi reboque 2 eixos + 1 eixo direcional (Canguru)",
    image: image7,
  },
  {
    value: 6,
    label: "Semi reboque 3 eixos espaçados (Vanderleia)",
    image: image8,
  },
  {
    value: 10,
    label: "Semi Reboque Bitrem 4 eixos",
    image: image9,
  },
  {
    value: 14,
    label: "Semi reboque Bitrem 6 eixos (Bitrenzão com 5° roda )",
    image: image10,
  },
  {
    value: 1,
    label: "Semi reboque rodo trem 6 eixos (Rodotrem com dolly)",
    image: image11,
  },
  {
    value: 18,
    label: "Semi reboque Rodo trem 6 eixos (Tri trem com 5° roda )",
    image: image13,
  },
];

export const pbtcs = [
  { value: 1, label: "33 ton" },
  { value: 2, label: "40 ton" },
  { value: 3, label: "41.5 ton" },
  { value: 4, label: "43 ton" },
  { value: 5, label: "46 ton" },
  { value: 6, label: "48.5 ton" },
  { value: 7, label: "50 ton" },
  { value: 8, label: "53 ton" },
  { value: 9, label: "57 ton" },
  { value: 10, label: "74 ton" },
];

export const truckloadFactors = [
  { value: 1, label: "50%" },
  { value: 2, label: "75%" },
  { value: 3, label: "100%" },
];

export const truckloadTypes = [
  { value: 1, label: "Asfalto" },
  { value: 2, label: "Baú fracionado" },
  { value: 3, label: "Baú geral" },
  { value: 4, label: "Camara fria congelados" },
  { value: 5, label: "Camara fria resfriados" },
  { value: 6, label: "Carga indivisível leve" },
  { value: 7, label: "Carga indivisível pesada" },
  { value: 8, label: "Cegonha" },
  { value: 9, label: "Granel líquido" },
  { value: 10, label: "Granel solido basculante" },
  { value: 11, label: "Granel solido Graneleiro" },
  { value: 12, label: "Madeira fora de estrada" },
  { value: 13, label: "Porta bebidas" },
  { value: 14, label: "Porta bobina de aço" },
  { value: 15, label: "Porta container 20 pés" },
  { value: 16, label: "Porta container 40 pés" },
  { value: 17, label: "Porta gado (boideiro)" },
  { value: 18, label: "Porta vidros" },
  { value: 19, label: "Sider automotivos" },
  { value: 20, label: "Silo pó granel" },
  { value: 21, label: "Combustível" },
  { value: 22, label: "Químico" },
  { value: 23, label: "Laticínio" },
  { value: 24, label: "Indivisível (Transportes Especiais)" },
  { value: 25, label: "Automóveis" },
  { value: 26, label: "Caminhões" },
  { value: 27, label: "Máquinas Agrícolas" },
  { value: 28, label: "Máquinas para construção" },
  { value: 29, label: "Moveis" },
  { value: 30, label: "Cargas frogoríficadas" },
  { value: 31, label: "Cargas perigosas " },
  { value: 32, label: "Cargas fracionadas (Encomendas)" },
  { value: 33, label: "Cimento a granel" },
  { value: 34, label: "Cimento em saca" },
  { value: 35, label: "Adudo em Bag" },
  { value: 36, label: "Adubo a granel " },
  { value: 37, label: "Calcario em Bag" },
  { value: 38, label: "Calcario a granel " },
  { value: 39, label: "Sementes em Bag" },
  { value: 40, label: "Sementes a granel" },
  { value: 41, label: "Minerio" },
  { value: 42, label: "Areia" },
  { value: 43, label: "Bebidas" },
  { value: 44, label: "Ração" },
  {
    value: 45,
    label: "Transporte de cargas vivas (gado, galinhas, porco etc)",
  },
  { value: 48, label: "Bobinas de aço" },
  { value: 49, label: "Bobinas de papel" },
  { value: 50, label: "Polietileno" },
  { value: 53, label: "Granel Solido (soja, arroz,milho e feijão  etc)" },
  { value: 54, label: "Peças diversas " },
  { value: 55, label: "Fertilizante" },
  { value: 56, label: "Container" },
  { value: 57, label: "Carga seca (outros)" },
  { value: 60, label: "Indivisivel Leve" },
  { value: 61, label: "Cana de açúcar" },
  { value: 62, label: "Madeira em Geral" },
];

export const optionRadioGrade = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];

export const routeSeverity = [
  { value: 1, label: "OFF Road" },
  { value: 2, label: "Misto" },
  { value: 3, label: "Rodoviário" },
];
