export const modelsOptions = [
  { value: 10, label: "DAF" },
  { value: 20, label: "FORD" },
  { value: 30, label: "IVECO" },
  { value: 40, label: "MAN" },
  { value: 50, label: "MERCEDES" },
  { value: 60, label: "SCANIA" },
  { value: 70, label: "VOLKSVAGEN" },
  { value: 80, label: "VOLVO" },
];

export const dafModels = [
  { value: 101, label: "CF85" },
  { value: 102, label: "XF105 OFF-ROAD" },
  { value: 103, label: "XF" },
  { value: 104, label: "XF105" },
];

export const fordModels = [
  { value: 201, label: "C-816" },
  { value: 202, label: "C-1119" },
  { value: 203, label: "C-1419" },
  { value: 204, label: "C-1519" },
  { value: 205, label: "C-1519 TORQSHIFT" },
  { value: 206, label: "C-1719" },
  { value: 207, label: "C-1719 TORQSHIFT" },
  { value: 208, label: "C-1723" },
  { value: 209, label: "C-1723 KOLECTOR" },
  { value: 2010, label: "C-1723 TORQSHIFT" },
  { value: 2011, label: "C-1723 KOLECTOR TORQSHIFT" },
  { value: 2012, label: "C-1731 R" },
  { value: 2013, label: "C-1731 R TORQSHIFT" },
  { value: 2014, label: "C-1731 TRACTOR" },
  { value: 2015, label: "C-1731 TRACTOR TORQSHIFT" },
  { value: 2016, label: "C-1933 R" },
  { value: 2017, label: "C-1933 R TORQSHIFT" },
  { value: 2018, label: "C-2431 TRACTOR TORQSHIFT" },
  { value: 2019, label: "C-2042 TRACTOR" },
  { value: 2020, label: "C-2423 (6X2)" },
  { value: 2021, label: "C-2429 (6X2)" },
  { value: 2022, label: "C-2431 TORQSHIFT" },
  { value: 2023, label: "C-2431 (6X2)" },
  { value: 2024, label: "C-2623 (6X4)" },
  { value: 2025, label: "C-2629 (6X4)" },
  { value: 2026, label: "C-2631 (6X4)" },
  { value: 2027, label: "C-2631 (6X4) TORQSHIFT" },
  { value: 2028, label: "C-2842 (6X2)" },
  { value: 2029, label: "C-3031 (8X2)" },
  { value: 2030, label: "C-3031 (8X2) TORQSHIFT" },
  { value: 2031, label: "C-3131 (6X4)" },
  { value: 2032, label: "C-3131 (6X4) TORQSHIFT" },
  { value: 2033, label: "C-3133 (6X4)" },
  { value: 2034, label: "C-3133 (6X4) TORQSHIFT" },
  { value: 2035, label: "F-350" },
  { value: 2036, label: "F-4000" },
  { value: 2037, label: "F-4000 4X4" },
];

export const ivecoModels = [
  { value: 301, label: "TECTOR ATTACK 150E21 (4X2)" },
  { value: 301, label: "TECTOR ATTACK 170E21 (4X2)" },
  { value: 301, label: "TECTOR 170E28 (4X2)" },
  { value: 301, label: "TECTOR 24-280 (6X2)" },
  { value: 301, label: "TECTOR 24-300 AUTO-SHIFT (6X2)" },
  { value: 301, label: "TECTOR (6X4)" },
];

export const manModels = [
  { value: 401, label: "TGM" },
  { value: 402, label: "TGS" },
  { value: 403, label: "TGX" },
];

export const mercedesModels = [
  { value: 501, label: "ACTROS 2546 6X2" },
  { value: 502, label: "ACTROS 2646 6X4" },
  { value: 503, label: "ACTROS 2651 6X4" },
  { value: 504, label: "ACTROS 4844 8X4" },
  { value: 505, label: "ACCELO 815 4X2" },
  { value: 506, label: "ACCELO 1016 4X2" },
  { value: 507, label: "ACCELO 1316 6X2" },
  { value: 508, label: "ATEGO 1419 4X2" },
  { value: 509, label: "ATEGO 1719 4X2" },
  { value: 5010, label: "ATEGO 1726 4X2" },
  { value: 5011, label: "ATEGO 1726 4X4" },
  { value: 5012, label: "ATEGO 1729 4X2 COLETOR DE LIXO" },
  { value: 5013, label: "ATEGO 1730 4X2 CV MECÂNICO" },
  { value: 5014, label: "ATEGO 2426 6X2" },
  { value: 5015, label: "ATEGO 2430 6X2" },
  { value: 5016, label: "ATEGO 2730 6X2" },
  { value: 5017, label: "ATEGO 3026 8X2" },
  { value: 5018, label: "ATEGO 3030 8X2" },
  { value: 5019, label: "ATRON 1635 4X2" },
  { value: 5020, label: "AXOR 1933 4X2" },
  { value: 5021, label: "AXOR 2036 4X2" },
  { value: 5022, label: "AXOR 2041 4X2" },
  { value: 5023, label: "AXOR 2533 6X2" },
  { value: 5024, label: "AXOR 2536 6X2" },
  { value: 5025, label: "AXOR 2544 6X2" },
  { value: 5026, label: "AXOR 2644 6X4" },
  { value: 5027, label: "AXOR 3131 6X4 BASCULANTE" },
  { value: 5028, label: "AXOR 3131 6X4 BETONEIRA" },
  { value: 5029, label: "AXOR 3131 6X4 PLATAFORMA" },
  { value: 5030, label: "AXOR 3344 6X4 BASCULANTE" },
  { value: 5031, label: "AXOR 3344 6X4 CV MECÂNICO" },
  { value: 5032, label: "AXOR 3344 6X4 PLATAFORMA" },
  { value: 5033, label: "AXOR 4144 6X4 BASCULANTE" },
];

export const scaniaModels = [
  { value: 601, label: "SERIE G", valueCategory: 6 },
  { value: 602, label: "SERIE P", valueCategory: 6 },
  { value: 603, label: "SERIE R", valueCategory: 6 },
  { value: 603, label: "SERIE S", valueCategory: 6 },
];

export const volksvagenModels = [
  { value: 701, label: "CONSTELLATION 14.190" },
  { value: 702, label: "CONSTELLATION 17.190" },
  { value: 703, label: "CONSTELLATION 17.230" },
  { value: 704, label: "CONSTELLATION 15.190 4X4" },
  { value: 705, label: "CONSTELLATION 17.260" },
  { value: 706, label: "CONSTELLATION 17.280" },
  { value: 707, label: "CONSTELLATION 17.280 T" },
  { value: 708, label: "CONSTELLATION 17.330" },
  { value: 709, label: "CONSTELLATION 19.330 TITAN" },
  { value: 7010, label: "CONSTELLATION 19.360" },
  { value: 7011, label: "CONSTELLATION 19.420" },
  { value: 7012, label: "CONSTELLATION 23.230" },
  { value: 7013, label: "CONSTELLATION 24.260" },
  { value: 7014, label: "CONSTELLATION 24.280" },
  { value: 7015, label: "CONSTELLATION 24.330" },
  { value: 7016, label: "CONSTELLATION 25.360" },
  { value: 7017, label: "CONSTELLATION 25.420" },
  { value: 7018, label: "CONSTELLATION 26.260" },
  { value: 7019, label: "CONSTELLATION 26.280" },
  { value: 7020, label: "CONSTELLATION 26.420" },
  { value: 7021, label: "CONSTELLATION 30.280" },
  { value: 7022, label: "CONSTELLATION 30.330" },
  { value: 7023, label: "CONSTELLATION 31.280" },
  { value: 7024, label: "CONSTELLATION 31.330" },
  { value: 7025, label: "CONSTELLATION 31.390" },
  { value: 7026, label: "CONSTELLATION 32.360" },
  { value: 7027, label: "CONSTELLATION 33.460" },
  { value: 7028, label: "DELIVERY EXPRESS" },
  { value: 7029, label: "DELIVERY 4.160" },
  { value: 7030, label: "DELIVERY 6.160" },
  { value: 7031, label: "DELIVERY 9.170" },
  { value: 7032, label: "DELIVERY 11.180" },
  { value: 7033, label: "DELIVERY 11.180 4X4" },
  { value: 7034, label: "DELIVERY 13.180" },
  { value: 7035, label: "METEOR 28.460" },
  { value: 7036, label: "METEOR 29.520" },
];

export const volvoModels = [
  { value: 801, label: "FH", valueCategory: 8 },
  { value: 802, label: "FM", valueCategory: 8 },
  { value: 803, label: "FMX", valueCategory: 8 },
  { value: 804, label: "VM", valueCategory: 8 },
];

export const fetchCategoriesVehicle = (value) => {
  switch (value) {
    case 10:
      return dafModels;
    case 20:
      return fordModels;
    case 30:
      return ivecoModels;
    case 40:
      return manModels;
    case 50:
      return mercedesModels;
    case 60:
      return scaniaModels;
    case 70:
      return volksvagenModels;
    case 80:
      return volvoModels;

    default:
      return "";
  }
};
