import React, { useEffect } from 'react'
import Aux from '../../../hoc/auxiliar'
import Paper from '@material-ui/core/Paper'
import { makeDefaultValues } from '../../../domain/initialValues/user/addUser'
import Grid from '@material-ui/core/Grid'
import PageTitle from '../../../components/PageTitle/PageTitle'
import Button from '@material-ui/core/Button'
import { useForm, useFormState, FormProvider, useWatch } from 'react-hook-form'
import schemaValidation from '../../../helpers/validations/yup/addUserValidation'
import Input from '../../../components/react-hook-form/input'
import useStyles from './styles'
import { yupResolver } from "@hookform/resolvers/yup";
import { createUser } from '../../../services/user'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'


const AddUser = () => {
  const classes = useStyles()
  const history = useHistory()
  const defaultValues = makeDefaultValues();
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });
  const { setValue } = methods
  const { errors } = useFormState({ control: methods.control });
  const firstName = useWatch({ control: methods.control, name: 'firstName' })
  const lastName = useWatch({ control: methods.control, name: 'lastName' })

  const onSubmit = async (data) => {
    try {
      const completeData = {
        ...data,
        roleId: 100,
        companyId: 48,
        locationId: 1
      }
      const response = await createUser(completeData)
      console.log(response)
      if (response.status === 200 || response.status == 201) {
        toast.success('Usuário criado com sucesso')
        history.replace('/app/dashboard')
      } else {
        toast.error('Error ao criar usuário. Contate o suporte')
      }
    } catch (error) {
      toast.error('Internal Error')
    }

  };

  const makeUserName = (initialName = '', finalName = '') => {
    //regra: primeira letra do nome + ponto + sobrenome
    if (initialName && finalName) {
      const firstLetter = initialName[0].toLowerCase()
      const lastName = finalName.toLowerCase().trim().split(' ')[0]
      const finalUserName = `${firstLetter}.${lastName}`
      return finalUserName
    }

  }

  useEffect(() => {
    console.log('effect');
    const userName = makeUserName(firstName, lastName)
    setValue('username', userName)
    setValue('name', `${firstName} ${lastName}`)

  }, [firstName, lastName])

  return (
    <Aux>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <PageTitle title="Cadastrar Usuário" />
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Input
                  size="small"
                  name="firstName"
                  label="Nome"
                  fullWidth
                  helperText={errors.firstName?.message}
                  error={errors.firstName && true}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Input
                  size="small"
                  name="lastName"
                  label="Sobrenome"
                  fullWidth
                  helperText={errors.lastName?.message}
                  error={errors.lastName && true}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Input
                  name="email"
                  label="Email"
                  fullWidth
                  size="small"
                  helperText={errors.email?.message}
                  error={errors.email && true}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Input
                  type="password"
                  name="password"
                  label="Senha"
                  fullWidth
                  size="small"
                  helperText={errors.password?.message}
                  error={errors.password && true}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Input
                  type="password"
                  name="passwordConfirm"
                  label="Confirmação de Senha"
                  fullWidth
                  size="small"
                  helperText={errors.passwordConfirm?.message}
                  error={errors.passwordConfirm && true}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Input
                  name="username"
                  label="Username"
                  variant="filled"
                  fullWidth
                  size="small"
                  readOnly
                  helperText={errors.username?.message}
                  error={errors.username && true}
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container alignItems="center" justify="flex-end">
                  <Grid item xl={1} lg={1} md={2} sm={4} xs={12} >
                    <Button fullWidth type="submit" variant="contained" color="primary">
                      SALVAR
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Paper>
    </Aux>
  );
};

export default AddUser;