import React from "react";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
import { phoneMask } from "../../../helpers/masks";

const COLUMNS = [
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        if (value === "finished") {
          return (
            <Grid container>
              <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                <Badge color="primary" variant="dot" />
              </Grid>
              <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                <span style={{ marginLeft: 2 }}>Finalizado</span>
              </Grid>
            </Grid>
          );
        } else {
          return (
            <Grid container>
              <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                <Badge color="error" variant="dot" />
              </Grid>
              <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                <span style={{ marginLeft: 5 }}>Em Aberto</span>
              </Grid>
            </Grid>
          );
        }
      },
    },
  },
  {
    name: "dealershipName",
    label: "Concessionária",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "companyName",
    label: "Empresa",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "cnpj",
    label: "CNPJ",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "testDriveResponsible",
    label: "Contato 1",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "email",
    label: "Email",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "phone",
    label: "Telefone",
    options: {
      customBodyRender: (value) => phoneMask(value),
      filter: true,
      sort: true,
    },
  },
  {
    name: "chassisName",
    label: "Veículo",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "startDate",
    label: "Data Inicio",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "finishDate",
    label: "Data Fim",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "engine",
    label: "Motor",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "axisConfiguration",
    label: "Configuração de Eixo",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "differentialRatio",
    label: "Relação Diferencial",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "implementType",
    label: "Tipo de Implemento",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "implementSetup",
    label: "Configuração do Implemento",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "truckloadType",
    label: "Tipo de Carga",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "routeSeverity",
    label: "Severidade da Rota",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "maxSpeed",
    label: "Velocidade Máxima",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "pbtc",
    label: "PBTC",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "truckloadFactor",
    label: "Fator de Carga %",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "consumptionAverage",
    label: "Média de Consumo",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "consumptionAverageDAF",
    label: "Média de Consumo DAF",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "achievedGoal",
    label: "Atingiu média esperada",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        if (!value) {
          return <Typography align="center">Não Preenchido</Typography>;
        } else {
          return value === "s" ? "Sim" : "Não";
        }
      },
    },
  },
  {
    name: "becomeSale",
    label: "Gerou venda",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        if (!value) {
          return <Typography align="center">Não Preenchido</Typography>;
        } else {
          return value === "s" ? "Sim" : "Não";
        }
      },
    },
  },
  {
    name: "buyIntentionLevel",
    label: "Intenção de Compra",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        if (!value) {
          return <Typography align="center">Não Preenchido</Typography>;
        } else {
          return value;
        }
      },
    },
  },
  {
    name: "satisfactionLevel",
    label: "Grau de Satisfação",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        if (!value) {
          return <Typography align="center">Não Preenchido</Typography>;
        } else {
          return value;
        }
      },
    },
  },
];

export default COLUMNS;
