import api from "./api";

export const createDealership = async (data) => {
  try {
    const response = await api.post("v1/dealership", data);
    return response;
  } catch (err) {
    return err
  }
};

export const getAllDealerships = async () => {
  try {
    const response = await api.get("/v1/dealership");
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getDealershipById = async (id) => {
  try {
    const response = await api.get(`/v1/dealership/${id}`);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const deleteDealership = async (id) => {
  try {
    const response = await api.delete(`/v1/dealership/${id}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const updateDealership = async (data) => {
  try {
    const response = await api.put(`/v1/dealership/${data.id}`, data);
    return response;
  } catch (err) {
    return err;
  }
};
