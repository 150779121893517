import React, { useState, useCallback, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Select from "../../components/react-hook-form/Select";
import { orderByName } from "../../helpers/functions";
import { REGIONS, fetchCities } from "../../helpers/location";
import { getAllCities } from "../../services/city";
import Aux from "../../hoc/auxiliar";
import { useFormContext, useWatch, useFormState } from 'react-hook-form'
import useStyles from "./styles";


const Location = ({
  onLoadingCities,
  labelRegion = "Região de localização",
  nameRegion = "region",
  labelState = "Estado",
  nameState = "state",
  labelCity = "Cidade",
  nameCity = "city",
}) => {
  const classes = useStyles();
  const { control, watch } = useFormContext()
  const { errors, isSubmitSuccessful } = useFormState({ control })
  const state = useWatch({ control, name: 'state' })
  const region = useWatch({ control, name: 'region' })

  const [optionsState, setOptionsState] = useState([]);
  const [optionsCities, setOptionsCities] = useState([]);

  const onSelectState = useCallback(
    async (idUf) => {
      onLoadingCities(true);
      try {
        const response = await getAllCities(idUf);
        const transformedResponse = response.map((cid) => ({
          value: cid.ID,
          label: cid.Name,
        }));
        setOptionsCities(transformedResponse);

        onLoadingCities(false);
      } catch (err) {
        console.log(err);
        onLoadingCities(false);
      }
    },
    [onLoadingCities],
  );

  const fetchAllOptionsOriginState = (value) => {
    setOptionsState(orderByName(fetchCities(value)));
  };

  useEffect(() => {
    if (region) {
      fetchAllOptionsOriginState(region.value);
    }
  }, [region, state]);

  useEffect(() => {
    if (state) {
      onSelectState(state.value);
    }
  }, [state, onSelectState]);

  return (
    <Aux>
      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
        <Select
          isDisabled={isSubmitSuccessful}
          label={labelRegion}
          name={nameRegion}
          options={orderByName(REGIONS)}
          placeholder="Selecione uma região"
        />
        {errors.region && (
          <p className={classes.feedback}>{errors.region.message}</p>
        )}
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
        <Select
          isDisabled={!watch(nameRegion) || isSubmitSuccessful}
          label={labelState}
          name={nameState}
          options={optionsState}
          placeholder="Selecione um estado"
        />
        {errors.state && (
          <p className={classes.feedback}>{errors.state.message}</p>
        )}
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
        <Select
          isDisabled={!watch(nameState) || isSubmitSuccessful}
          label={labelCity}
          name={nameCity}
          options={optionsCities}
          placeholder="Selecione uma cidade"
        />
        {errors.city && (
          <p className={classes.feedback}>{errors.city.message}</p>
        )}
      </Grid>
    </Aux>
  );
};

export default Location;
