export default class TestDrive {
  constructor(
    id,
    axisConfiguration,
    chassisNumber,
    companyId,
    consumptionAverage,
    dealershipId,
    differentialRatio,
    engine,
    finishDate,
    implementSetup,
    implementType,
    maxSpeed,
    pbtc,
    routes,
    startDate,
    truckloadFactor,
    truckloadType,
    status,
  ) {
    this.id = id;
    this.axisConfiguration = axisConfiguration;
    this.chassisNumber = chassisNumber;
    this.companyId = companyId;
    this.consumptionAverage = consumptionAverage;
    this.dealershipId = dealershipId;
    this.differentialRatio = differentialRatio;
    this.engine = engine;
    this.finishDate = finishDate;
    this.implementSetup = implementSetup;
    this.implementType = implementType;
    this.maxSpeed = maxSpeed;
    this.pbtc = pbtc;
    this.routes = routes;
    this.startDate = startDate;
    this.truckloadFactor = truckloadFactor;
    this.truckloadType = truckloadType;
    this.status = status;
  }
}

export class TestDriveFinishModel {
  constructor(
    id,
    achievedGoal,
    becomeSale,
    buyIntentionLevel,
    consumptionAverage,
    customerFeedback,
    dealershipNotes,
    testDrivefinishdate,
    finalScore,
    satisfactionLevel,
    totalDistance,
  ) {
    this.id = id;
    this.becomeSale = becomeSale;
    this.buyIntentionLevel = buyIntentionLevel;
    this.achievedGoal = achievedGoal;
    this.consumptionAverage = consumptionAverage;
    this.customerFeedback = customerFeedback;
    this.dealershipNotes = dealershipNotes;
    this.testDrivefinishdate = testDrivefinishdate;
    this.finalScore = finalScore;
    this.satisfactionLevel = satisfactionLevel;
    this.totalDistance = totalDistance;
  }
}
