import React from 'react'
import CustomModal from '../../../CustomModal'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'

const CheckCompany = props => {
  return (
    <CustomModal
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      maxWidth="md"
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography component="h1" variant="h4"> Esta empresa esta vinculada à(s) seguinte(s) concessionária(s): </Typography>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ul>
            {props.data.map(item => <li>{item.dealershipName}</li>)}
          </ul>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container justify="flex-end">
            <Button color="primary" onClick={props.handleClose}>Fechar</Button>
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  )
}
export default CheckCompany