import { create } from 'apisauce';

// define the api
const api = create({
  baseURL: 'http://api.gobrax.com.br:8085',
  timeout: 15000,
});

api.axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage?.getItem('token') || '';
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

api.addResponseTransform((response) => {
  if (!response.ok) {
    throw response;
  }
});

export default api;
