import * as yup from "yup";

export default yup.object().shape({
  city: yup
    .object({ value: yup.string(), label: yup.string() })
    .required("Selecione uma opção")
    .nullable(),
  companyName: yup.string().required("Campo obrigatório"),
  cnpj: yup
    .string()
    .min(18, "Preencha corretamente")
    .required("Campo obrigatório"),
  region: yup
    .object({ value: yup.string(), label: yup.string() })
    .required("Selecione uma opção")
    .nullable(),
  state: yup
    .object({ value: yup.number(), label: yup.string() })
    .required("Selecione uma opção")
    .nullable(),
  testDriveResponsible1: yup.string().required("Campo obrigatório"),
  email1: yup.string().email("Email inválido").required("Campo obrigatório"),
  phone1: yup.string().required("Campo obrigatório"),
});
