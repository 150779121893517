import React, { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import CustomToolbar from "../../components/CustomToolbar";
import Table from "mui-datatables";
import translatedOptionsTable from "../../components/Table/options";
import { Link as RouterLink } from "react-router-dom";
import Aux from "../../hoc/auxiliar";
import { deleteDealership, getAllDealerships } from "../../services/dealership";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "./styles";
import { toast } from "react-toastify";
const COLUMMS = [
  {
    name: "name",
    label: "Nome",
    options: {
      filter: true,
      sort: false,
    },
  },
];

export default function List(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedData, setSelectedData] = useState([]);
  const [dealerships, setDealerships] = useState([]);
  const classes = useStyles();

  const addButton = (
    <Button
      component={RouterLink}
      to="/app/dealerships/new-dealership"
      title="Adicionar"
      variant="contained"
      color="secondary"
    >
      Adicionar
    </Button>
  );

  //Deve retornar lista de concessionarias.
  const fetchDealerships = async () => {
    const response = await getAllDealerships();
    if (response.data.dealership) {
      setDealerships(response.data.dealership);
    }
    
    setIsLoading(false);
  };

  //Faz o delete da concessionaria no bd.
  const deleteSelectedDealerships = async (id) => {
    const response = await deleteDealership(id);
    if (response.status === 200 || response.status === 201) {
      toast.success(`Concessionária removida com sucesso!`);
    } else {
      toast.error(
        `Erro ao remover concessionária. Caso persista, entre em contato com o suporte`,
      );
    }
    await fetchDealerships();
  };

  //useEffect vai ser chamado todas as vezes que a pagina for carregada.
  useEffect(() => {
    fetchDealerships();
  }, []);

  let content = (
    <Grid item lg={12} className={classes.progress}>
      <CircularProgress color="primary" size={40} />
    </Grid>
  );

  if (!isLoading) {
    content = (
      <Table
        title="Concessionárias"
        columns={COLUMMS}
        data={dealerships}
        options={{
          ...translatedOptionsTable,
          customToolbarSelect: () => (
            <CustomToolbar
              selectedData={selectedData}
              path="/app/dealerships/edit"
              onDeleteCompanies={() =>
                deleteSelectedDealerships(selectedData.id)
              }
            />
          ),
          filterType: "multiselect",
          selectableRows: "single",
          fixedHeader: true,
          print: true,
          download: true,
          viewColumns: true,
          filter: true,
          search: true,
          searchPlaceholder: "Busca por nome da Concessionária",
          onRowSelectionChange: (currentRowSelected, allRowsSelected) => {
            const data = dealerships.find(
              (deal, index) => index === currentRowSelected[0].dataIndex,
            );
            setSelectedData(data);
          },
          onRowsDelete: (deleted) => console.log(deleted),
        }}
      />
    );
  }

  return (
    <Aux>
      <PageTitle title="Concessionárias" button={addButton} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {content}
        </Grid>
      </Grid>
    </Aux>
  );
}
