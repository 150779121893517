import React, { useState, useCallback, useEffect } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import Aux from "../../hoc/auxiliar";
import { useLocation } from "react-router-dom";
import EditCompany from "../../components/Company";
import CompanyModel from "../../models/Company";
import { updateCompany, getCompanyById } from "../../services/company";
import { Button, Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { getCity } from "../../services/city";
import { findObjectByKey } from "../../helpers/functions";
import { REGIONS, fetchCities } from "../../helpers/location";
import { toast } from "react-toastify";
import {
  fetchCategoriesVehicle,
  modelsOptions,
} from "../../helpers/truckModel";
import { onlyNumberMask } from '../../helpers/masks'

export default function Edit(props) {

  const { state } = useLocation(); //parametro que recebo os dados vindo da pagina de lista de companias.
  const [companyEdit, setCompanyEdit] = useState([]);

  //Para cada objeto vindo do parametro state, recupera os dados da cidade, tendo em vista que só vem o ID
  //da cidade e nao é possivel preencher os campos com apenas os ids.
  const fetchCitiesOnEditData = async (data) => {
    const responseCompany = await getCompanyById(data.id);
    const responseCity = await getCity(responseCompany.data.city);
    const formatedData = {
      ...data,
      region: findObjectByKey(REGIONS, responseCity.data.Region),
      state: findObjectByKey(
        fetchCities(findObjectByKey(REGIONS, responseCity.data.Region).value),
        +responseCity.data.ID?.toString().slice(0, 2),
      ),
      cityId: {
        value: responseCity.data.ID,
        label: responseCity.data.Name,
      },
      fleet: responseCompany.data.fleet
        ? responseCompany.data.fleet.map((fleet) => ({
          ...fleet,
          model: findObjectByKey(
            modelsOptions,
            +fleet.model.toString().slice(0, 2),
          ),
          modelLabel: findObjectByKey(
            modelsOptions,
            +fleet.model.toString().slice(0, 2),
          ).label,
          category: findObjectByKey(
            fetchCategoriesVehicle(+fleet.model.toString().slice(0, 2)),
            fleet.model,
          ),
          categoryLabel: findObjectByKey(
            fetchCategoriesVehicle(+fleet.model.toString().slice(0, 2)),
            fleet.model,
          ).label,
        }))
        : [],
    };
    setCompanyEdit(formatedData);
  };

  //Ao clicar no botao de cancelar da pagina de edit, deve remover o objeto da pagina

  const onSubmit = async (data) => {
    const companyModel = new CompanyModel(
      data.id,
      data.city.value,
      onlyNumberMask(data.cnpj),
      data.email1,
      data.email2,
      data.companyName,
      data.fleets
        .map((fleet) => ({
          ...fleet,
          consumption: +fleet.consumption,
          model: fleet.category.value,
          quantity: +fleet.quantity,
        }))
        .filter(
          (fleet) =>
            (fleet.id && fleet.delete) || (fleet.isNewFleet && !fleet.delete),
        ),
      onlyNumberMask(data.phone1),
      onlyNumberMask(data.phone2),
      data.testDriveResponsible1,
      data.testDriveResponsible2,
    );
    const response = await updateCompany(companyModel);
    if (response.status === 200 || response.status === 201) {
      toast.success("Empresa atualizada com sucesso!");
      props.history.push("/app/companies/");
    } else {
      toast.error(
        "Erro ao atualizar empresa, verifique os dados. Caso persista, entre em contato com o suporte",
      );
    }
  };

  useEffect(() => {
    if (state) {
      fetchCitiesOnEditData(state.company);
    }
  }, [state]);

  let content;
  if (!state) {
    content = (
      <Grid container spacing={3}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Alert severity="error">
            Erro ao carregar dados para editar, selecione novamente!
          </Alert>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Button
            title="Adicionar"
            variant="contained"
            color="secondary"
            onClick={props.history.goBack}
          >
            VOLTAR
          </Button>
        </Grid>
      </Grid>
    );
  } else {
    content = (
      <EditCompany
        editData={companyEdit}
        key={companyEdit.id}
        onSubmit={onSubmit}
        onCancel={props.history.goBack}
      />
    );
  }

  return (
    <Aux>
      <PageTitle title="Editar empresa(s)" />
      {content}
    </Aux>
  );
}
