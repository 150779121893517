import React, { useState, useEffect, useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from '../react-hook-form/input'
import Aux from "../../hoc/auxiliar";
import useStyles, { getMuiTheme } from "./styles";
import Modal from "../../components/CustomModal";
import translatedOptionsTable from "../../components/Table/options";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Table from "mui-datatables";
import { columns } from "./columnsTable";
import {
  Button,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Backdrop,
  DialogActions,
  IconButton,
  Typography
} from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import schemaValidation from '../../helpers/validations/yup/dealership'
import Select from "../../components/react-hook-form/Select";
import { useForm, useFormState, useWatch, FormProvider } from "react-hook-form";
import Location from "../../components/Location";
import { getAllUsers } from "../../services/user";

const Dealership = ({ onCancel, editData, onSubmit }) => {
  const classes = useStyles();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [dataEditTable, setDataEditTable] = useState([]);
  const [userOptions, setUserOptions] = useState([]);

  const initialState = {
    city: editData ? editData.city : "",
    dealershipName: editData ? editData.name : "",
    id: editData ? editData.id : "",
    region: editData ? editData.region : "",
    state: editData ? editData.state : "",
    users: "",
  };

  const methods = useForm({ defaultValues: initialState, resolver: yupResolver(schemaValidation) });

  const { handleSubmit, control, watch, setValue } = methods

  const { errors, isSubmitSuccessful } = useFormState({ control })

  const dealershipName = useWatch({ control, name: 'dealershipName' })
  const region = useWatch({ control, name: 'region' })
  const state = useWatch({ control, name: 'state' })
  const city = useWatch({ control, name: 'city' })
  const users = useWatch({ control, name: 'users' })



  const fetchUsers = async () => {
    setOpenBackdrop(true);
    const response = await getAllUsers();
    const formatedResponse = response.data.users ? response.data.users.map((user) => ({
      ...user,
      value: user.id,
      label: `${user.first_name} ${user.last_name}`,
    })) : [];
    setUserOptions(formatedResponse);
    setOpenBackdrop(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (dealershipName) {
      const capitalizeDealershipName = dealershipName.charAt(0).toUpperCase() + dealershipName.slice(1)
      setValue('dealershipName', capitalizeDealershipName)
    }

  }, [dealershipName, setValue]);

  useEffect(() => {
    if (!editData) {
      if (city.label) {
        setValue('dealershipName', `${dealershipName} - ${city.label}`)
      }
    }
  }, [city])



  const showBackdrop = useCallback((value) => setOpenBackdrop(value), []);

  const onConfirmModalHandler = () => {
    setDataTable((prevState) => [
      ...prevState,
      {
        fullName: `${users.first_name} ${users.last_name}`,
        id: users.id,
        username: users.username,
        email: users.email,
      },
    ]);

    setDataEditTable((prevState) => [
      ...prevState,
      {
        isNewUser: true,
        id: users.id,
        username: users.username,
        email: users.email,
      },
    ]);
    setModalOpen(false);
  };

  useEffect(() => {
    setValue("state", "");
    setValue("city", "");
  }, [region, setValue]);

  useEffect(() => {
    setValue("city", "");
  }, [state, setValue]);

  useEffect(() => {
    if (editData) {
      setValue("state", state);
      setValue("city", city);
      setDataTable(editData.users);
      setDataEditTable(editData.users);
    }
  }, [editData, setValue]);

  return (
    <Aux>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card className={classes.root}>
        <CardContent>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit((data) =>
                onSubmit({
                  ...data,
                  id: editData ? editData.id : "",
                  users: dataEditTable,
                }),
              )}
            >
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Input
                    error={errors.dealershipName ? true : false}
                    label="Nome da concessionária"
                    size="small"
                    name="dealershipName"
                    helperText={errors.dealershipName?.message}
                    fullWidth
                    disabled={isSubmitSuccessful}
                  />
                  <Typography className={classes.warningName}>*Nome da cidade será incluso ao selecionar a cidade.</Typography>
                </Grid>

                <Location
                  control={control}
                  onLoadingCities={showBackdrop}
                  watch={watch}
                />
              </Grid>

              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.table}
              >
                <MuiThemeProvider theme={getMuiTheme}>
                  <Table
                    data={dataTable}
                    title="RESPONSÁVEIS DA CONCESSIONÁRIA"
                    columns={columns}
                    options={{
                      customToolbar: () => (
                        <IconButton
                          onClick={() => setModalOpen(true)}
                          disabled={isSubmitSuccessful}
                        >
                          <Add />
                        </IconButton>
                      ),
                      ...translatedOptionsTable,
                      search: false,
                      print: false,
                      viewColumns: false,
                      download: false,
                      selectableRows: "single",
                      filter: false,
                      onRowsDelete: (deleted) => {
                        setDataTable((prevState) =>
                          prevState.filter(
                            (data, index) => index !== deleted.data[0].dataIndex,
                          ),
                        );

                        setDataEditTable((prevState) =>
                          prevState.map((data, index) => {
                            if (index === deleted.data[0].dataIndex) {
                              return {
                                ...data,
                                deleteAt: true,
                              };
                            }
                            return data;
                          }),
                        );
                      },
                    }}
                  />
                </MuiThemeProvider>

                <Modal
                  handleClose={() => setModalOpen(false)}
                  isOpen={modalOpen}
                  title="Vincular usuário à Concessionária"
                >
                  <Grid container>
                    <Grid item xl={12} l12={4} md={12} sm={12} xs={12}>
                      <Select
                        control={control}
                        disabled={isSubmitSuccessful}
                        fullWidth
                        label="Usuário"
                        name="users"
                        options={userOptions}
                        placeholder="Selecione uma opção"
                        size="small"
                      />
                    </Grid>
                  </Grid>

                  <DialogActions>
                    <Button color="primary" onClick={() => setModalOpen(false)}>
                      Cancelar
                    </Button>
                    <Button
                      autoFocus
                      color="primary"
                      onClick={onConfirmModalHandler}
                    >
                      Confirmar
                    </Button>
                  </DialogActions>
                </Modal>
              </Grid>

              <Grid
                alignItems="center"
                className={classes.buttonGroup}
                container
                direction="row"
                justify="flex-end"
                spacing={3}
              >
                <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                  <Button
                    disabled={isSubmitSuccessful}
                    fullWidth
                    onClick={onCancel}
                    variant="contained"
                  >
                    CANCELAR
                  </Button>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                  <Button
                    color="secondary"
                    disabled={isSubmitSuccessful}
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    SALVAR
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </CardContent>
      </Card>
    </Aux>
  );
};

export default Dealership;
