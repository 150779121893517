export const columns = [
  {
    name: "fullName",
    label: "Nome",
    options: {
      filter: true,
      sort: false,
    },
  },

  {
    name: "username",
    label: "Usuário",
    options: {
      filter: true,
      sort: false,
    },
  },

  {
    name: "email",
    label: "Email",
    options: {
      filter: true,
      sort: false,
    },
  },
];
