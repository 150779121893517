import React, { useState, useCallback, useEffect } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import Aux from "../../hoc/auxiliar";
import { useLocation } from "react-router-dom";
import EditDealership from "../../components/Dealership";
import { updateDealership, getDealershipById } from "../../services/dealership";
import DealershipModel from "../../models/Dealership";
import { Button, Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { findObjectByKey } from "../../helpers/functions";
import { findRegionByState, fetchCities } from "../../helpers/location";
import { toast } from "react-toastify";

export default function Edit(props) {
  const { state } = useLocation();
  const [dealershipsEdit, setDealershipsEdit] = useState(null);

  //Para cada objeto vindo do parametro state, recupera os dados da cidade, tendo em vista que só vem o ID
  //da cidade e nao é possivel preencher os campos com apenas os ids.
  const formatDataEdit = async (data) => {
    const response = await getDealershipById(data.id);
    const formatedData = {
      ...data,
      region: findRegionByState(+data.cityId.toString().slice(0, 2)),
      state: findObjectByKey(
        fetchCities(
          findRegionByState(+data.cityId.toString().slice(0, 2)).value,
        ),
        +data.cityId.toString().slice(0, 2),
      ),
      city: {
        value: data.cityId,
        label: data.cityName,
      },
      users: response.data.users ? response.data.users.map((user) => ({
        ...user,
        fullName: `${user.firstName} ${user.lastName}`,
        username: user.userName,
      })) : [],
    };
    setDealershipsEdit(formatedData);
  };

  const onSubmit = async (data) => {
    const deletedUsers = data.users.filter((user) => user.id && user.deleteAt).map(user => ({ id: user.id, delete: user.deleteAt }))
    const newUsers = data.users.filter((user) => user.isNewUser && !user.deleteAt).map((user) => ({ id: user.id }))
    const updatedUsers = [...deletedUsers, ...newUsers]

    const dealershipModel = new DealershipModel(
      data.id,
      data.city.value,
      data.dealershipName,
      updatedUsers,
    );
    const response = await updateDealership(dealershipModel);
    if (response.status === 200 || response.status === 201) {
      toast.success("Concessionária atualizada com sucesso");
      props.history.push("/app/dealerships/");
    } else {
      toast.error("Erro ao atualizar Concessionária");
    }
  };

  useEffect(() => {
    if (state) {
      formatDataEdit(state.company);
    }
  }, [state]);

  if (!dealershipsEdit && state) {
    return "...Loading";
  }

  return (
    <Aux>
      <PageTitle title="Editar concessionária(s)" />
      {state ? (
        <EditDealership
          key={dealershipsEdit.id}
          editData={dealershipsEdit}
          onSubmit={onSubmit}
          onCancel={props.history.goBack}
        />
      ) : (
        <Grid container spacing={3}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Alert severity="error">
              Erro ao carregar dados para editar, selecione novamente!
            </Alert>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Button
              title="Adicionar"
              variant="contained"
              color="secondary"
              onClick={props.history.goBack}
            >
              VOLTAR
            </Button>
          </Grid>
        </Grid>
      )}
    </Aux>
  );
}
