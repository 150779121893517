import React from "react";
import Company from "../../components/Company";
import CompanyModel from "../../models/Company";
import PageTitle from "../../components/PageTitle/PageTitle";
import Aux from "../../hoc/auxiliar";
import { createCompany } from "../../services/company";
import { toast } from "react-toastify";
import { onlyNumberMask } from '../../helpers/masks'

export default function Create(props) {
  const onSubmit = async (data) => {
    const removeCharactersCnpj = data.cnpj.replace(/\D/g, "");
    const companyModel = new CompanyModel(
      data.id,
      data.city.value,
      removeCharactersCnpj,
      data.email1,
      data.email2,
      data.companyName,
      data.fleets.map((fleet) => ({
        consumption: +fleet.consumption,
        model: fleet.category.value,
        quantity: +fleet.quantity,
      })),
      onlyNumberMask(data.phone1),
      onlyNumberMask(data.phone2),
      data.testDriveResponsible1,
      data.testDriveResponsible2,
    );

    const response = await createCompany(companyModel);
    if (response.status === 200 || response.status === 201) {
      toast.success("Empresa criada com sucesso");
      props.history.push("/app/companies/");
    } else {
      toast.error("Erro ao criar empresa");
    }
  };

  return (
    <Aux>
      <PageTitle title="Cadastrar Empresa" />
      <Company onSubmit={onSubmit} onCancel={props.history.goBack} />
    </Aux>
  );
}
