import { phoneMask } from "../../../helpers/masks";
export const makeColumns = () => {
  return [
    {
      name: "name",
      label: "Nome",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "cnpj",
      label: "CNPJ",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "testDriveResponsible1",
      label: "Contato 1",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "email1",
      label: "Email",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "phone1",
      label: "Telefone",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => phoneMask(value),
      },
    },
  ];
};
