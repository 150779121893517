export default [
  {
    name: "originLabel",
    label: "Origem",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "destinyLabel",
    label: "Destino",
    options: {
      filter: true,
      sort: false,
    },
  },
];