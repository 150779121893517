import React, { useState, useEffect, useCallback } from "react";
import CheckCompany from "./components/checkCompany";
import TruckCard from "./components/truckCard";
import CustomModal from "../../components/CustomModal";
import Input from "../react-hook-form/input";
import Table from "mui-datatables";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import Aux from "../../hoc/auxiliar";
import { yupResolver } from "@hookform/resolvers/yup";
import translatedOptionsTable from "../../components/Table/options";
import {
  Button,
  DialogContentText,
  DialogActions,
  Box,
  Grid,
  CircularProgress,
  Card,
  CardContent,
  Backdrop,
  Typography,
  Divider,
} from "@material-ui/core";
import { getAllTestDrive } from "../../services/testDrive";
import { Link as RouterLink } from "react-router-dom";
import { useForm, FormProvider, useWatch, useFormState } from "react-hook-form";
import { add } from "date-fns";
import Select from "../../components/react-hook-form/Select";
import DatePicker from "../react-hook-form/DatePicker";
import Route from "../../components/Route";
import Modal from "../../components/CustomModal";
import RadioButtons from "../../components/Radio";
import { MuiThemeProvider } from "@material-ui/core/styles";

//Services
import { getAllDealerships } from "../../services/dealership";
import { getAllCompanies } from "../../services/company";
import { getVehicles, getVehicle } from "../../services/vehicle";

//Static selects options
import {
  axisConfigurations,
  differentialRatios,
  engines,
  implementTypes,
  pbtcs,
  truckloadFactors,
  truckloadTypes,
  optionRadioGrade,
  implementSetup,
  routeSeverity as routeSeverityOptions,
} from "./config/selectsOptions";

import { findObjectByKey, orderByName } from "../../helpers/functions";
import { onlyNumberMask } from "../../helpers/masks";
import {
  mainSchema,
  secondarySchema,
} from "../../helpers/validations/yup/testDriver";
import COLUMNS from "./config/tableColumns";
import yesOrNoOptions from "../../domain/selectsOptions/yesOrNo";

// styles
import useStyles, { getMuiTheme } from "./styles";

// Page
export default function TestDrive({ editData, onSubmit, onSubmitFinishForm }) {
  const classes = useStyles();
  const [optionsDealership, setOptionsDealership] = useState([]);
  const [optionsCompanies, setOptionsCompanies] = useState([]);
  const [optionsChassis, setOptionsChassis] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalToolbarOpen, setModalToolbarOpen] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [openImplementDescription, setOpenImplementDescription] =
    useState(false);
  const [testDrivers, setTestDrivers] = useState(null);
  const [existingCompanies, setExistingCompanies] = useState([]);
  const [isCheckOpen, setIsCheckOpen] = useState(false);

  const finished =
    editData && editData.status && editData.status === "finished";

  //Valores default para inicializar useForm;
  let initialState = {
    axisConfiguration: editData ? editData.axisConfiguration : "",

    chassisNumber: editData ? editData.chassisNumber : "",

    company: editData ? editData.companyId : "",
    consumptionAverage: editData ? String(editData.consumptionAverage) : "",

    dealership: editData ? editData.dealershipId : "",

    destinyRegion: editData ? editData.destinyRegion : "",
    destinyState: editData ? editData.destinyState : "",
    differentialRatio: editData ? editData.differentialRatio : "",
    engine: editData ? editData.engine : "",
    finishDate: editData
      ? add(new Date(editData.finishDate), { days: 1 })
      : add(new Date(), { days: 1 }),
    implementSetup: editData ? editData.implementSetup : "",
    implementType: editData ? editData.implementType : "",
    maxSpeed: editData ? editData.maxSpeed : "",
    pbtc: editData ? editData.pbtc : "",
    routes: [],
    routeDestinationId: editData ? editData.routeDestinationId : "",
    routeOriginId: editData ? editData.routeOriginId : "",
    routeSeverity: editData ? editData.pbtc : "",
    originRegion: editData ? editData.originRegion : "",
    originState: editData ? editData.originState : "",
    satisfactionLevel: "1",
    startDate: editData ? new Date(editData.startDate) : new Date(),
    terrainType: editData ? editData.terrainType : "",

    truckloadType: editData ? editData.truckloadType : "",
    truckloadFactor: editData ? editData.truckloadFactor : "",
  };

  const initialMethods = useForm({
    defaultValues: initialState,
    resolver: yupResolver(mainSchema),
  });
  const { control, setValue, handleSubmit, trigger } = initialMethods;
  const { errors } = useFormState({ control });

  const dealership = useWatch({ control, name: "dealership" });
  const chassisNumber = useWatch({ control, name: "chassisNumber" });
  const consumptionAverage = useWatch({ control, name: "consumptionAverage" });
  const company = useWatch({ control, name: "company" });
  const destinyState = useWatch({ control, name: "destinyState" });
  const destinyRegion = useWatch({ control, name: "destinyRegion" });
  const finishDate = useWatch({ control, name: "finishDate" });
  const originState = useWatch({ control, name: "originState" });
  const originRegion = useWatch({ control, name: "originRegion" });
  const routeOriginId = useWatch({ control, name: "routeOriginId" });
  const routeDestinationId = useWatch({ control, name: "routeDestinationId" });
  const startDate = useWatch({ control, name: "startDate" });
  const maxSpeed = useWatch({ control, name: "maxSpeed" });
  const routes = useWatch({ control, name: "routes" });

  useEffect(() => {
    setValue("maxSpeed", onlyNumberMask(maxSpeed));
  }, [maxSpeed]);

  useEffect(() => {
    if (consumptionAverage) {
      const fixAverage = consumptionAverage.replace(",", ".");
      setValue("consumptionAverage", fixAverage);
    }
  }, [consumptionAverage]);


  useEffect(() => {
    if (company && !editData) {
      const foundCompanies = testDrivers?.filter(
        (testDrive) => testDrive.companyName === company.label,
      );
      if (foundCompanies && foundCompanies.length > 0) {
        setExistingCompanies(foundCompanies);
        setIsCheckOpen(true);
        // alert(`Cuidado, a empresa ${company.label} já esta vinculada à concessionária ${existingCompany.dealershipName}`)
      }
    }
  }, [company]);

  //SECOND FORM

  const initialStateFinishForm = {
    achievedGoal: editData ? editData.achievedGoal : "n",
    becomeSale: editData ? editData.becomeSale : "n",
    buyIntentionLevel: editData ? editData.buyIntentionLevel : "",
    consumptionAverage: editData ? editData.consumptionAverage : "",
    customerFeedback: editData ? editData.customerFeedback : "",
    dealershipNotes: editData ? editData.dealershipNotes : "",
    satisfactionLevel: editData ? editData.satisfactionLevel : "",
    testDrivefinishdate:
      editData && editData.testDrivefinishdate
        ? add(new Date(editData.testDrivefinishdate), { days: 1 })
        : add(new Date(), { days: 1 }),
    totalDistance: editData ? editData.totalDistance : "",
  };

  const finishMethods = useForm({
    defaultValues: initialStateFinishForm,
    resolver: yupResolver(secondarySchema),
  });
  const { handleSubmit: handleSubmitFinish, control: controlFinishForm } =
    finishMethods;

  const {
    errors: finishErrors,
    isSubmitSuccessful: isSubmitSuccessfulFinishForm,
  } = useFormState({ control: controlFinishForm });

  const handleClickTruckCard = (value) => {
    setValue("implementType", value);
    setOpenImplementDescription(false);
  };

  const showBackdrop = (value) => setOpenBackdrop(value);

  const closeModalHandler = () => setOpenModal(false);

  const addRouteOnTable = () => {
    const route = {
      routeOriginId,
      routeDestinationId,
      originLabel: `${routeOriginId.label} - ${originState.label}`,
      destinyLabel: `${routeDestinationId.label} - ${destinyState.label}`,
    };
    setDataTable((prevState) => [...prevState, route]);
    setValue("routes", [...routes, route], { shouldValidate: true });
  };

  const onConfirmRoutesHandler = async () => {
    const result = await trigger([
      "originRegion",
      "originState",
      "routeOriginId",
      "destinyRegion",
      "destinyState",
      "routeDestinationId",
    ]);
    if (result) {
      await trigger(["routes"]);
      addRouteOnTable();
      setModalToolbarOpen(false);
    }
  };

  //recupera lista de empresas cadastradas
  const fetchAllCompanies = async () => {
    const response = await getAllCompanies();
    const formatedResponse = response.data.company.map((company) => {
      return {
        label: company.name,
        value: company.id,
      };
    });
    if (editData) {
      setValue(
        "company",
        findObjectByKey(formatedResponse, editData.companyId),
      );
    }
    setOptionsCompanies(formatedResponse);
  };

  //recupera lista de concessionarias
  const fetchDealership = async () => {
    const response = await getAllDealerships();
    if (response.data.dealership) {
      const formatedResponse = response.data.dealership.map((company) => {
        return {
          label: company.name,
          value: company.id,
        };
      });

      if (editData) {
        setValue(
          "dealership",
          findObjectByKey(formatedResponse, editData.dealershipId),
        );
      }
      setOptionsDealership(formatedResponse);
    }
  };

  //Recupera lista de chassis cadastrados em cada concessionaria.
  const fetchVehicles = useCallback(async () => {
    if (dealership) {
      const response = await getVehicles(
        dealership.value ? dealership.value : dealership,
      );
      let formatedResponse = response.data.vehicle?.map((vehicle) => {
        return {
          label: vehicle.chassisNumber,
          value: vehicle.id,
        };
      });
      formatedResponse = formatedResponse ? formatedResponse : [];
      if (editData) {
        setValue(
          "chassisNumber",
          findObjectByKey(formatedResponse, editData.chassisNumber),
        );
      }
      setOptionsChassis(formatedResponse);
    }
  }, [dealership]);

  //Deve retornar lista de testDrivers.
  const fetchTestDrivers = async () => {
    const response = await getAllTestDrive();
    if (response.data.testDrive) {
      const formatedData = response.data.testDrive.map((test) => ({
        companyName: test.companyName,
        dealershipName: test.dealershipName,
      }));
      setTestDrivers(formatedData);
    }
  };

  //Retorna valores defaults de MOTOR/CONFING EIXO/REL.DIF para cada chassi selecionado.
  //Apos recuprar valores é setado o valor default nos selects atraves do setValue
  const fetchVehicle = useCallback(
    async (vehicle) => {
      setOpenBackdrop(true);
      if (vehicle) {
        const response = await getVehicle(vehicle.value);
        setValue(
          "axisConfiguration",
          findObjectByKey(axisConfigurations, response.data.axisConfiguration),
          { shouldValidate: true },
        );
        setValue("engine", findObjectByKey(engines, response.data.engine), {
          shouldValidate: true,
        });
        setValue(
          "differentialRatio",
          findObjectByKey(differentialRatios, response.data.differentialRatio),
          { shouldValidate: true },
        );
      }
      setOpenBackdrop(false);
    },
    [setValue, editData],
  );

  const onClickImplementDescription = () => setOpenImplementDescription(true);

  const handleCloseImplementDescription = () =>
    setOpenImplementDescription(false);

  //Recupera dados de concessionaria e empresas no carregamento da pagina.
  useEffect(() => {
    fetchDealership();
    fetchAllCompanies();
    fetchTestDrivers();
    setLoading(false);
  }, []);

  //Chama a função a qualquer mudança do valor de dealership
  useEffect(() => {
    fetchVehicles();
  }, [dealership, fetchVehicles]);

  //Chama a função a qualquer mudança do valor de chassisNumber
  useEffect(() => {
    if (!editData) {
      fetchVehicle(chassisNumber);
      setValue("deal");
    }
  }, [chassisNumber, fetchVehicle]);

  //Todos os useffects abaixo sao para controle das
  //validaçoes de campos de rotas.

  useEffect(() => {
    setValue("originState", "");
    setValue("routeOriginId", "");
  }, [originRegion, setValue]);

  useEffect(() => {
    setValue("routeOriginId", "");
  }, [originState, setValue]);

  useEffect(() => {
    setValue("destinyState", "");
    setValue("routeDestinationId", "");
  }, [destinyRegion, setValue]);

  useEffect(() => {
    setValue("routeDestinationId", "");
  }, [destinyState, setValue]);

  useEffect(() => {
    if (editData) {
      setDataTable(editData.routes);
    }
  }, [editData]);

  if (loading) {
    return (
      <Grid container display="flex" alignItems="center" justify="center">
        <CircularProgress color="primary" />
      </Grid>
    );
  }
  return (
    <Aux>
      <CheckCompany
        isOpen={isCheckOpen}
        data={existingCompanies}
        handleClose={() => setIsCheckOpen(false)}
      />
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card className={classes.root} style={{ overflow: "visible" }}>
        <CardContent>
          <Box container="true" display="flex" className={classes.boxForm}>
            <FormProvider {...initialMethods}>
              <form
                onSubmit={handleSubmit((data) =>
                  onSubmit({
                    ...data,
                    id: editData ? editData.id : "",
                    routes: dataTable,
                  }),
                )}
              >
                <Grid container spacing={3}>
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Select
                      label="Concessionária"
                      placeholder="Selecione uma opcão"
                      name="dealership"
                      isDisabled={editData ? true : false}
                      options={optionsDealership}
                    />
                    {errors.dealership && (
                      <p className={classes.feedback}>
                        {errors.dealership?.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <DatePicker
                      label="Data de Inicio"
                      name="startDate"
                      maxDate={finishDate}
                    />
                    {errors.startDate && (
                      <p className={classes.errorDataPicker}>
                        Selecione este campo
                      </p>
                    )}
                  </Grid>
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <DatePicker
                      label="Data Prevista de Término"
                      name="finishDate"
                      minDate={startDate}
                    />
                    {errors.finishDate && (
                      <p className={classes.errorDataPicker}>
                        Selecione este campo
                      </p>
                    )}
                  </Grid>
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Select
                      label="Empresa"
                      name="company"
                      placeholder="Selecione uma opção"
                      options={optionsCompanies}
                      isDisabled={editData ? true : false}
                    />

                    {errors.company && (
                      <p className={classes.feedback}>
                        {errors.company.message}
                      </p>
                    )}
                  </Grid>

                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Select
                      label="Chassi"
                      name="chassisNumber"
                      placeholder="Selecione uma opção"
                      options={optionsChassis}
                    />
                    {errors.chassisNumber && (
                      <p className={classes.feedback}>
                        {errors.chassisNumber.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Select
                      isDisabled
                      label="Motor"
                      name="engine"
                      placeholder="Selecione uma opção"
                      options={engines}
                    />

                    {errors.engine && (
                      <p className={classes.feedback}>
                        {errors.engine.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Select
                      isDisabled
                      label="Configuração de Eixo"
                      name="axisConfiguration"
                      placeholder="Selecione uma opção"
                      options={axisConfigurations}
                    />
                    {errors.axisConfiguration && (
                      <p className={classes.feedback}>
                        {errors.axisConfiguration.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Select
                      isDisabled
                      label="Relação Diferencial"
                      name="differentialRatio"
                      placeholder="Selecione uma opção"
                      options={differentialRatios}
                    />

                    {errors.differentialRatio && (
                      <p className={classes.feedback}>
                        {errors.differentialRatio.message}
                      </p>
                    )}
                  </Grid>

                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Select
                      label="Tipo de Implemento"
                      name="implementType"
                      placeholder="Selecione uma opção"
                      options={implementTypes}
                    />
                    <CustomModal
                      isOpen={openImplementDescription}
                      handleClose={handleCloseImplementDescription}
                      maxWidth="lg"
                      overflowY="scroll"
                    >
                      <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                          >
                            Descrição dos implementos - Selecione uma opcão
                          </Typography>
                          <Divider />
                        </Grid>
                        {orderByName(implementTypes).map((truckType, index) => (
                          <TruckCard
                            key={index}
                            image={truckType.image}
                            label={truckType.label}
                            onClick={handleClickTruckCard.bind(this, {
                              value: truckType.value,
                              label: truckType.label,
                            })}
                          />
                        ))}
                      </Grid>
                    </CustomModal>
                    <Button
                      className={classes.buttonDescription}
                      onClick={onClickImplementDescription}
                    >
                      Veja a descrição
                    </Button>

                    {errors.implementType && (
                      <p className={classes.feedback}>
                        {errors.implementType.message}
                      </p>
                    )}
                  </Grid>

                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Select
                      label="Configuração do Implemento"
                      name="implementSetup"
                      placeholder="Selecione uma opção"
                      options={orderByName(implementSetup)}
                    />

                    {errors.implementSetup && (
                      <p className={classes.feedback}>
                        {errors.implementSetup.message}
                      </p>
                    )}
                  </Grid>

                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Select
                      label="Tipo de Carga"
                      name="truckloadType"
                      placeholder="Selecione uma opção"
                      options={orderByName(truckloadTypes)}
                    />

                    {errors.truckloadType && (
                      <p className={classes.feedback}>
                        {errors.truckloadType.message}
                      </p>
                    )}
                  </Grid>

                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Select
                      label="PBTC"
                      name="pbtc"
                      options={pbtcs}
                      placeholder="Selecione uma opção"
                    />

                    {errors.pbtc && (
                      <p className={classes.feedback}>{errors.pbtc.message}</p>
                    )}
                  </Grid>
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Select
                      label="Fator de Carga %"
                      name="truckloadFactor"
                      options={truckloadFactors}
                      placeholder="Selecione uma opção"
                    />

                    {errors.truckloadFactor && (
                      <p className={classes.feedback}>
                        {errors.truckloadFactor.message}
                      </p>
                    )}
                  </Grid>

                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Select
                      label="Severidade da Rota"
                      name="routeSeverity"
                      options={routeSeverityOptions}
                      placeholder="Selecione uma opção"
                    />

                    {errors.routeSeverity && (
                      <p className={classes.feedback}>
                        {errors.routeSeverity.message}
                      </p>
                    )}
                  </Grid>

                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Input
                      className={classes.textFiled}
                      label="Velocidade Máxima"
                      name="maxSpeed"
                      fullWidth
                      placeholder="Velocidade Máxima"
                      size="small"
                      error={errors.maxSpeed ? true : false}
                      helperText={errors.maxSpeed?.message}
                    />
                  </Grid>

                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Input
                      label="Média de Consumo"
                      name="consumptionAverage"
                      fullWidth
                      size="small"
                      placeholder="Média consumo"
                      error={errors.consumptionAverage ? true : false}
                      helperText={errors.consumptionAverage?.message}
                    />
                  </Grid>

                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <MuiThemeProvider theme={getMuiTheme()}>
                      <Table
                        data={dataTable}
                        title="Rotas"
                        columns={COLUMNS}
                        options={{
                          customToolbar: () => (
                            <IconButton
                              onClick={() => setModalToolbarOpen(true)}
                            >
                              <Add />
                            </IconButton>
                          ),
                          ...translatedOptionsTable,
                          search: false,
                          print: false,
                          viewColumns: false,
                          download: false,
                          selectableRows: "single",
                          filter: false,
                          onRowsDelete: (deleted) =>
                            deleted.data.map((route) =>
                              setDataTable((prevState) =>
                                prevState.filter(
                                  (state, index) => index !== route.dataIndex,
                                ),
                              ),
                            ),
                        }}
                      />
                    </MuiThemeProvider>
                    {errors.routes && (
                      <p className={classes.feedback} style={{ marginLeft: 0 }}>
                        {errors.routes?.message}
                      </p>
                    )}
                  </Grid>

                  <Modal
                    isOpen={modalToolbarOpen}
                    handleClose={() => setModalToolbarOpen(false)}
                    maxWidth="md"
                  >
                    <p>ADICIONAR ROTA</p>
                    <Route onLoadingCities={showBackdrop} />
                    <DialogActions>
                      <Button
                        onClick={() => setModalToolbarOpen(false)}
                        color="primary"
                      >
                        Cancelar
                      </Button>
                      <Button
                        color="primary"
                        autoFocus
                        onClick={onConfirmRoutesHandler}
                      >
                        Confirmar
                      </Button>
                    </DialogActions>
                  </Modal>
                </Grid>

                <Grid
                  container
                  display="flex"
                  alignItems="center"
                  justify="flex-end"
                  spacing={3}
                >
                  <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
                    <Button
                      fullWidth
                      component={RouterLink}
                      to="/app/testdrives"
                      variant="contained"
                      disabled={isSubmitSuccessfulFinishForm}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  {editData && (
                    <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={() => setOpenModal(true)}
                        disabled={finished}
                      >
                        Finalizar
                      </Button>
                    </Grid>
                  )}
                  <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
                    <Button
                      fullWidth
                      disabled={isSubmitSuccessfulFinishForm || finished}
                      variant="contained"
                      color="secondary"
                      type="submit"
                    >
                      {editData ? "SALVAR" : "CADASTRAR"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Box>
        </CardContent>
      </Card>
      <Grid item xl={8} lg={8} md={8}>
        <Modal
          handleClose={closeModalHandler}
          maxWidth="sm"
          defaultOverflow
          isOpen={openModal}
          title="Finalizar Test Drive"
        >
          <FormProvider {...finishMethods}>
            <form
              onSubmit={handleSubmitFinish((data) => {
                setOpenBackdrop(true);
                onSubmitFinishForm({ ...data, id: editData.id });
                setOpenBackdrop(false);
                setOpenModal(false);
              })}
            >
              <Grid container spacing={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <DialogContentText>
                    Ao finalizar o Test Drive você confirma o fim do período de
                    testes do veículo {chassisNumber?.label}
                  </DialogContentText>
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <DatePicker
                    label="Data de Término"
                    name="testDrivefinishdate"
                    minDate={startDate}
                  />
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <RadioButtons
                    label="Test Drive gerou venda?"
                    name="becomeSale"
                    row
                    options={yesOrNoOptions}
                  />
                  {finishErrors.becomeSale && (
                    <p className={classes.feedback} style={{ marginTop: 0 }}>
                      {finishErrors.becomeSale?.message}
                    </p>
                  )}
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <RadioButtons
                    label="Atingiu média esperada?"
                    name="achievedGoal"
                    row
                    options={yesOrNoOptions}
                  />
                  {finishErrors.achievedGoal && (
                    <p className={classes.feedback} style={{ marginTop: 0 }}>
                      {finishErrors.achievedGoal?.message}
                    </p>
                  )}
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <Input
                    fullWidth
                    label="KM Total Percorrido"
                    name="totalDistance"
                    size="small"
                    error={finishErrors.totalDistance ? true : false}
                    helperText={finishErrors.totalDistance?.message}
                    type="number"
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <Input
                    label="Média de consumo"
                    name="consumptionAverage"
                    size="small"
                    error={finishErrors.consumptionAverage ? true : false}
                    helperText={finishErrors.consumptionAverage?.message}
                    type="number"
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <RadioButtons
                    label="Qual a intenção de compra?"
                    name="buyIntentionLevel"
                    row
                    options={optionRadioGrade}
                    labelPlacement="bottom"
                  />
                  {finishErrors.buyIntentionLevel && (
                    <p className={classes.feedback} style={{ marginTop: 0 }}>
                      {finishErrors.buyIntentionLevel?.message}
                    </p>
                  )}
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <RadioButtons
                    label="O quanto foi satisfatório o Test Drive?"
                    name="satisfactionLevel"
                    row
                    labelPlacement="bottom"
                    options={optionRadioGrade}
                  />
                  {finishErrors.satisfactionLevel && (
                    <p className={classes.feedback} style={{ marginTop: 0 }}>
                      {finishErrors.satisfactionLevel?.message}
                    </p>
                  )}
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Input
                    label="Sugestões do Cliente"
                    name="customerFeedback"
                    fullWidth
                    size="small"
                    multiline
                    rows={3}
                    error={finishErrors.customerFeedback ? true : false}
                    helperText={finishErrors.customerFeedback?.message}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Input
                    label="Observações do Concessionário"
                    name="dealershipNotes"
                    fullWidth
                    size="small"
                    multiline
                    rows={3}
                    error={finishErrors.dealershipNotes ? true : false}
                    helperText={finishErrors.dealershipNotes?.message}
                  />
                </Grid>
              </Grid>
              <DialogActions>
                <Button onClick={() => setOpenModal(false)} color="primary">
                  Cancelar
                </Button>
                <Button type="submit" color="primary" autoFocus>
                  Confirmar
                </Button>
              </DialogActions>
            </form>
          </FormProvider>
        </Modal>
      </Grid>
    </Aux>
  );
}
