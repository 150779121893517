import React, { useEffect, useState } from "react";
import Aux from "../../hoc/auxiliar";
import { Button, Grid, CircularProgress, Badge } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import translatedOptionsTable from "../../components/Table/options";
import Table from "mui-datatables";
import CustomToolbar from "../../components/CustomToolbar";
import { Link as RouterLink } from "react-router-dom";
import { getAllTestDrive, deleteTestDrive } from "../../services/testDrive";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import {
  axisConfigurations,
  differentialRatios,
  engines,
  implementSetup,
  implementTypes,
  pbtcs,
  truckloadFactors,
  truckloadTypes,
  routeSeverity,
} from "../../components/TestDrive/config/selectsOptions";
import COLUMNS from "./config/columnsListPage";

export default function List(props) {
  const [testDriveList, setTestDriveList] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTestDrivers();
  }, []);

  const fetchTestDrivers = async () => {
    const response = await getAllTestDrive();
    const formatedResponse = response.data.testDrive?.map((test) => ({
      ...test,
      chassisNumber: test.chassisNumber,
      finishDate: format(parseISO(test.finishDate), "dd/MM/yyyy"),
      startDate: format(parseISO(test.startDate), "dd/MM/yyyy"),
      name: test.companyName,
      axisConfiguration: axisConfigurations.find(
        (axis) => axis.value === test.axisConfiguration,
      )?.label,
      engine: engines.find((engine) => engine.value === test.engine)?.label,
      differentialRatio: differentialRatios.find(
        (dif) => dif.value === test.differentialRatio,
      )?.label,
      implementSetup: implementSetup.find(
        (implement) => implement.value === test.implementSetup,
      )?.label,
      implementType: implementTypes.find(
        (implement) => implement.value === test.implementType,
      )?.label,
      pbtc: pbtcs.find((pbtc) => pbtc.value === test.pbtc)?.label,
      routeSeverity: routeSeverity.find(
        (severity) => severity.value === test.routeSeverity,
      )?.label,
      truckloadFactor: truckloadFactors.find(
        (truckFactor) => truckFactor.value === test.truckloadFactor,
      )?.label,
      truckloadType: truckloadTypes.find(
        (truckType) => truckType.value === test.truckloadType,
      )?.label,
    }));
    setTestDriveList(formatedResponse);
    setLoading(false);
  };

  //Apos deletar empresa do banco de dados, deletara tambem da lista apos realizar o filtro.
  const deleteSelectedCompanies = async (id) => {
    const response = await deleteTestDrive(id);
    if (response.status === 200 || response.status === 201) {
      toast.success(`Test Drive removido com sucesso!`);
    } else {
      toast.error(
        `Erro ao remover Test Drive. Caso persista, entre em contato com o suporte`,
      );
    }
    await fetchTestDrivers();
  };

  const addButton = (
    <Button
      component={RouterLink}
      to="/app/testdrives/new-test-drive"
      title="Adicionar"
      variant="contained"
      color="secondary"
    >
      Adicionar
    </Button>
  );

  return (
    <Aux>
      {loading ? (
        <Grid container display="flex" alignItems="center" justify="center">
          <CircularProgress color="primary" />
        </Grid>
      ) : (
        <Aux>
          <PageTitle title="Test Drives" button={addButton} />
          <Grid container>
            <Grid item xs={12}>
              <Table
                title="Test Drives"
                columns={COLUMNS}
                data={testDriveList}
                options={{
                  ...translatedOptionsTable,
                  customToolbarSelect: () => (
                    <CustomToolbar
                      selectedData={selectedData}
                      path="/app/testdrives/edit"
                      onDeleteCompanies={() =>
                        deleteSelectedCompanies(selectedData.id)
                      }
                    />
                  ),
                  filterType: "multiselect",
                  selectableRows: "single",
                  fixedHeader: true,
                  print: true,
                  download: true,
                  viewColumns: true,
                  filter: true,
                  search: true,
                  searchPlaceholder: "Busca por nome da Concessionária",
                  onRowSelectionChange: (
                    currentRowSelected,
                    allRowsSelected,
                  ) => {
                    const data = testDriveList.find(
                      (deal, index) =>
                        index === currentRowSelected[0].dataIndex,
                    );
                    setSelectedData(data);
                  },
                }}
              />
            </Grid>
          </Grid>
        </Aux>
      )}
    </Aux>
  );
}
