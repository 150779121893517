import React, { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Link as RouterLink } from "react-router-dom";
import Aux from "../../hoc/auxiliar";
import Table from "mui-datatables";
import { deleteCompany, getAllCompanies } from "../../services/company";
import translatedOptionsTable from "../../components/Table/options";
import CustomToolbar from "../../components/CustomToolbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import useStyles from "./styles";
import { makeColumns } from "./config/tableColumns";

export default function List(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const classes = useStyles();
  const columns = makeColumns();
  const addButton = (
    <Button
      component={RouterLink}
      to="/app/companies/new-company"
      title="Adicionar"
      variant="contained"
      color="secondary"
    >
      Adicionar
    </Button>
  );

  //Deve retornar lista de companias.
  const fetchCompanies = async () => {
    const response = await getAllCompanies();
    if (response.data.company) {
      setCompanies(response.data.company);
    }
    setIsLoading(false);
  };

  //Remove empresa do bd
  const deleteSelectedCompanies = async (id) => {
    const response = await deleteCompany(id);
    if (response.status === 200 || response.status === 201) {
      toast.success(`Empresa removida com sucesso!`);
    } else {
      toast.error(
        `Erro ao remover empresa. Caso persista, entre em contato com o suporte`,
      );
    }
    await fetchCompanies();
  };

  //useEffect vai ser chamado todas as vezes que a pagina for carregada.
  useEffect(() => {
    fetchCompanies();
  }, []);

  let content = (
    <Grid item lg={12} className={classes.progress}>
      <CircularProgress color="primary" size={40} />
    </Grid>
  );

  if (!isLoading) {
    content = (
      <Table
        title="Empresas"
        columns={columns}
        data={companies}
        options={{
          ...translatedOptionsTable,
          customToolbarSelect: () => (
            <CustomToolbar
              selectedData={selectedData}
              path="/app/companies/edit"
              onDeleteCompanies={() => deleteSelectedCompanies(selectedData.id)}
            />
          ),
          filterType: "multiselect",
          selectableRows: "single",
          fixedHeader: true,
          print: true,
          download: true,
          viewColumns: true,
          filter: true,
          search: true,
          searchPlaceholder: "Busca por nome da Concessionária",
          onRowSelectionChange: (currentRowSelected, allRowsSelected) => {
            const data = companies.find(
              (deal, index) => index === currentRowSelected[0].dataIndex,
            );
            setSelectedData(data);
          },
          onRowsDelete: (deleted) => console.log(deleted),
        }}
      />
    );
  }

  return (
    <Aux>
      <PageTitle title="Empresas" button={addButton} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {content}
        </Grid>
      </Grid>
    </Aux>
  );
}
