import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  feedback: {
    color: "#f44336",
    margin: 0,
    marginLeft: 25,
    fontSize: "0.86rem",
    marginTop: "3px",
    textAlign: "left",
    fontFamily: "Roboto Helvetica Arial sans-serif",
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
  },
}));
