import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

  contentForm: {
    height: "100%",
  },

  boxForm: {
    display: "flex",
    height: "100%",
  },

  gridInput: {
    display: "flex",
    marginRight: theme.spacing(3),
    flexDirection: "column",
  },

  datePicker: {
    marginTop: 0,
    paddingTop: 0,
  },

  gridButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
  },

  inputGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },

  error: {
    fontSize: 10,
    marginLeft: 10,
    marginTop: 0,
    color: "#d32f2f",
  },

  errorDataPicker: {
    fontSize: 10,
    marginLeft: 10,
    marginTop: 23,
    color: "#d32f2f",
  },
  backButton: {
    marginTop: theme.spacing(2),
  },

  success: {
    backgroundColor: theme.palette.success.main,
    color: "#fff",
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: "#fff",
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
  },
}));
