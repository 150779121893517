export const REGIONS = [
  { value: "norte", label: "Norte" },
  { value: "nordeste", label: "Nordeste" },
  { value: "sudeste", label: "Sudeste" },
  { value: "sul", label: "Sul" },
  { value: "centroOeste", label: "Centro-Oeste" },
];

export const findRegionByState = (value) => {
  if (value >= 11 && value <= 17) {
    return { value: "norte", label: "Norte" };
  } else if (value >= 21 && value <= 29) {
    return { value: "nordeste", label: "Nordeste" };
  } else if (value >= 31 && value <= 35) {
    return { value: "sudeste", label: "Sudeste" };
  } else if (value >= 41 && value <= 43) {
    return { value: "sul", label: "Sul" };
  } else if (value >= 50 && value <= 53) {
    return { value: "centroOeste", label: "Centro-Oeste" };
  }
};

export const fetchCities = (region) => {
  switch (region) {
    case "norte":
      return [
        { value: 11, label: "Rondônia" },
        { value: 12, label: "Acre" },
        { value: 13, label: "Amazonas" },
        { value: 14, label: "Roraima" },
        { value: 15, label: "Pará" },
        { value: 16, label: "Amapá" },
        { value: 17, label: "Tocantins" },
      ];
    case "nordeste":
      return [
        { value: 21, label: "Maranhão" },
        { value: 22, label: "Piauí" },
        { value: 23, label: "Ceará" },
        { value: 24, label: "Rio Grande do Norte" },
        { value: 25, label: "Paraíba" },
        { value: 26, label: "Pernambuco" },
        { value: 27, label: "Alagoas" },
        { value: 28, label: "Sergipe" },
        { value: 29, label: "Bahia" },
      ];
    case "sudeste":
      return [
        { value: 31, label: "Minas Gerais" },
        { value: 32, label: "Espírito Santo" },
        { value: 33, label: "Rio de Janeiro" },
        { value: 35, label: "São Paulo" },
      ];
    case "sul":
      return [
        { value: 41, label: "Paraná" },
        { value: 42, label: "Santa Catarina" },
        { value: 43, label: "Rio Grande do Sul" },
      ];

    case "centroOeste":
      return [
        { value: 50, label: "Mato Groso do Sul" },
        { value: 51, label: "Mato Grosso" },
        { value: 52, label: "Goiás" },
        { value: 53, label: "Distrito Federal" },
      ];
    default:
      return [{ value: "", label: "Selecione a região" }];
  }
};
