export const COLUMNS = [
  {
    name: "modelLabel",
    label: "Marca",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "categoryLabel",
    label: "Modelo",
    options: {
      filter: true,
      sort: false,
    },
  },

  {
    name: "quantity",
    label: "Quantidade",
    options: {
      filter: true,
      sort: false,
    },
  },

  {
    name: "consumption",
    label: "Média KM/L",
    options: {
      filter: true,
      sort: false,
    },
  },
];