import api from "./api";
import { toast } from "react-toastify";

export const createCompany = async (data) => {
  try {
    const response = await api.post("v1/company", data);
    return response;
  } catch (err) {
    return err
  }
};

export const getAllCompanies = async () => {
  try {
    const response = await api.get("/v1/company", {
      limit: 1000,
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const getCompanyById = async (id) => {
  try {
    const response = await api.get(`/v1/company/${id}`);
    return response;
  } catch (err) {
    return err
  }
};

export const deleteCompany = async (id) => {
  try {
    const response = await api.delete(`/v1/company/${id}`);
    return response;
  } catch (err) {
    toast.error("Erro ao remover empresa")
  }
};



export const updateCompany = async (data) => {
  try {
    const response = await api.put(`/v1/company/${data.id}`, data);
    return response;
  } catch (err) {
    return err
  }
};
