import { makeStyles } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  buttonGroup: {
    marginTop: theme.spacing(2),
  },
  feedback: {
    color: "#f44336",
    margin: 0,
    marginLeft: 25,
    fontSize: "0.86rem",
    marginTop: "3px",
    textAlign: "left",
    fontFamily: "Roboto Helvetica Arial sans-serif",
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiTable: {
        root: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } },
        stickyHeader: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } },
      },
      MuiTableBody: { root: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } } },
      MuiScopedCssBaseline: {
        root: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } },
      },
      MuiTab: {
        root: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } },
        $selected: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } },
      },
      MuiTableCell: {
        head: {
          "& td": { zIndex: 0 },
          "& th": { zIndex: 0 },
        },
        root: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } },
        body: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } },
        footer: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } },
      },
      MuiTableHead: { root: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } } },
      MuiTableContainer: {
        root: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } },
      },
      MuiTableFooter: {
        root: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } },
      },
    },
  });
