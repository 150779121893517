import React from "react";
import { useController, useFormContext } from "react-hook-form";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import InputLabel from "@material-ui/core/InputLabel";

// Styles
import useStyles from "./styles";

export default function RadioButton({
  name,
  label,
  control,
  rules,
  watch,
  row,
  options,
  labelPlacement,
  ...props
}) {
  const classes = useStyles();
  const { control: contextControl } = useFormContext()
  const { field } = useController({ name, control: control ?? contextControl });

  return (
    <div className={classes.root}>
      <InputLabel shrink style={{width:'100%'}} >
        {label}
      </InputLabel>
      <RadioGroup row={row} aria-label={name} {...field}>
        {options.map((option) => (
          <FormControlLabel
            style={{ margin: 0 }}
            key={option.value}
            value={option.value}
            control={<Radio size="small" style={{ margin: 0, width: '33px' }} />}
            label={option.label}
            labelPlacement={labelPlacement}
            {...props}
          />
        ))}
      </RadioGroup>

    </div>
  );
}
