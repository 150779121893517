const fields = [
  "email",
  "firstName",
  "lastName",
  "password",
  "passwordConfirm",
  "username"
];

export const makeDefaultValues = (data = {}) => {
  let values = {}
  for (const field of fields) {
    values[field] = data[field] ?? ''
  }
  return values
}