import api from "./api";

export const getVehicles = async (dealershipId) => {
  try {
    const response = await api.get("/v1/dafvehicle", {
      dealershipId,
    });
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getVehicle = async (vehicleID) => {
  try {
    const resp = await api.get(`v1/dafvehicle/${vehicleID}`);
    return resp;
  } catch (err) {
    return err;
  }
};
