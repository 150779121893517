import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useStyles from "./styles";

export default function CustomModal({
  children,
  title,
  handleClose,
  isOpen,
  maxWidth,
  defaultOverflow,
  overflowY
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{ style: { overflowY: overflowY ?? "visible" } }}
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent className={defaultOverflow ? "" : classes.root}>
        {children}
      </DialogContent>
    </Dialog>
  );
}
