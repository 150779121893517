import { makeStyles } from "@material-ui/styles"
import { createMuiTheme } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    overflow: "visible",
  },
  responsive: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    with: "100%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "flex-end",
      marginTop: theme.spacing(3),
    },
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "30%",
    [theme.breakpoints.up("md")]: {
      width: "30%",
    },
  },
  progress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  feedback: {
    color: "#f44336",
    margin: 0,
    marginLeft: 25,
    fontSize: "0.86rem",
    marginTop: "3px",
    textAlign: "left",
    fontFamily: "Roboto Helvetica Arial sans-serif",
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 10,
    color: "#fff",
  },
  buttonDescription: { textTransform: "inherit", fontSize: '13px', padding: 0, color: theme.palette.primary.main, textDecoration: 'underline', marginLeft: 25 },
  rootCard: {
    width: '100%',
  }
}));

export const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiTable: {
        root: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } },
        stickyHeader: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } },
      },
      MuiTableBody: { root: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } } },
      MuiScopedCssBaseline: {
        root: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } },
      },
      MuiTab: {
        root: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } },
        $selected: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } },
      },
      MuiTableCell: {
        head: {
          "& td": { zIndex: 0 },
          "& th": { zIndex: 0 },
        },
        root: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } },
        body: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } },
        footer: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } },
      },
      MuiTableHead: { root: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } } },
      MuiTableContainer: {
        root: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } },
      },
      MuiTableFooter: {
        root: { "& td": { zIndex: 0 }, "& th": { zIndex: 0 } },
      },
    },
  });
