import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import ReactSelect from "react-select";
import { useController, useFormContext } from "react-hook-form";

// Styles
import useStyles from "./style";

export default function Select({ label, control, name, ...rest }) {
  const classes = useStyles();
  const { control: contextControl } = useFormContext()
  const { field } = useController({
    name,
    control: control ?? contextControl
  });

  return (
    <div className={classes.root}>
      <InputLabel shrink id="demo-simple-select-placeholder-label-label">
        {label}
      </InputLabel>
      <ReactSelect {...field} {...rest} />
    </div>
  );
}
