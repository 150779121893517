import React from 'react'
import useStyles from './styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'


const TruckCard = (props) => {
  const classes = useStyles();
  return (
    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
      <Card onClick={props.onClick}>
        <CardActionArea>
          <CardMedia
            className={classes.mediaCard}
            image={props.image}
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {props.label}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default TruckCard