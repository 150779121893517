import React, { useState, useEffect } from "react";
import FormLabel from "@material-ui/core/FormLabel";
import Aux from "../../hoc/auxiliar";
import Grid from "@material-ui/core/Grid";
import Select from "../../components/react-hook-form/Select";
import useStyles from "./styles";
import { useWatch, useFormContext, useFormState } from 'react-hook-form'
import { REGIONS, fetchCities } from "../../helpers/location";
import { orderByName } from "../../helpers/functions";
import { getAllCities } from "../../services/city";

const Route = ({ onLoadingCities }) => {
  const { control, setValue } = useFormContext()
  const { errors } = useFormState({ control })
  const classes = useStyles();

  const originRegion = useWatch({ control, name: 'originRegion' })
  const originState = useWatch({ control, name: 'originState' })
  const destinyRegion = useWatch({ control, name: 'destinyRegion' })
  const destinyState = useWatch({ control, name: 'destinyState' })


  const [optionsOriginState, setOptionsOriginState] = useState([]);
  const [optionsOriginCities, setOptionsOriginCities] = useState([]);

  const [optionsDestinyState, setOptionsDestinyState] = useState([]);
  const [optionsDestinyCities, setOptionsDestinyCities] = useState([]);

  const fetchAllOptionsOriginState = (value) =>
    setOptionsOriginState(orderByName(fetchCities(value)));

  const fetchAllOptionsDestinyState = (value) =>
    setOptionsDestinyState(orderByName(fetchCities(value)));

  const onSelectState = async (idUf) => {
    onLoadingCities(true);
    try {
      const response = await getAllCities(idUf);
      const transformedResponse = response.map((cid) => ({
        value: cid.ID,
        label: cid.Name,
      }));
      setOptionsOriginCities(transformedResponse);
      onLoadingCities(false);
    } catch (err) {
      console.log(err);
      onLoadingCities(false);
    }
  };

  const onSelectDestinyState = async (idUf) => {
    onLoadingCities(true);
    try {
      const response = await getAllCities(idUf);
      const transformedResponse = response.map((cid) => ({
        value: cid.ID,
        label: cid.Name,
      }));
      setOptionsDestinyCities(transformedResponse);
      onLoadingCities(false);
    } catch (err) {
      onLoadingCities(false);
    }
  };

  useEffect(() => {
    if (originRegion) {
      fetchAllOptionsOriginState(originRegion.value);
    }
  }, [originRegion, setValue]);

  useEffect(() => {
    if (destinyRegion) {
      fetchAllOptionsDestinyState(destinyRegion.value);
    }
  }, [destinyRegion, setValue]);

  useEffect(() => {
    if (originState) {
      onSelectState(originState.value);
    }
  }, [originState, setValue]);

  useEffect(() => {
    if (destinyState) {
      onSelectDestinyState(destinyState.value);
    }
  }, [destinyState, setValue]);

  return (
    <Aux>
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <fieldset className={classes.fieldset}>
            <FormLabel component="legend">Rota - Origem</FormLabel>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Select
                label="Região de Origem"
                name="originRegion"
                options={orderByName(REGIONS)}
                placeholder="Selecione uma opcao"
              />
              {errors.originRegion && (
                <p className={classes.feedback}>
                  {errors.originRegion.message}
                </p>
              )}
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Select
                label="Estado de Origem"
                name="originState"
                isDisabled={!originRegion}
                options={optionsOriginState}
                placeholder="Selecione uma opcao"
              />

              {errors.originState && (
                <p className={classes.feedback}>{errors.originState.message}</p>
              )}
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Select
                placeholder="Selecione uma opcao"
                label="Cidade de Origem"
                isDisabled={!originState}
                options={optionsOriginCities}
                name="routeOriginId"
              />
              {errors.routeOriginId && (
                <p className={classes.feedback}>
                  {errors.routeOriginId.message}
                </p>
              )}
            </Grid>
          </fieldset>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <fieldset className={classes.fieldset}>
            <FormLabel component="legend">Rota - Destino</FormLabel>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Select
                placeholder="Selecione uma opção"
                label="Região de Destino"
                options={REGIONS}
                name="destinyRegion"
              />
              {errors.destinyRegion && (
                <p className={classes.feedback}>
                  {errors.destinyRegion.message}
                </p>
              )}
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Select
                label="Estado de Destino"
                options={optionsDestinyState}
                name="destinyState"
                isDisabled={!destinyRegion}
                placeholder="Selecione uma opção"
              />
              {errors.destinyState && (
                <p className={classes.feedback}>
                  {errors.destinyState.message}
                </p>
              )}
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Select
                placeholder="Selecione uma opção"
                label="Cidade de Destino"
                isDisabled={!destinyState}
                options={optionsDestinyCities}
                name="routeDestinationId"
              />
              {errors.routeDestinationId && (
                <p className={classes.feedback}>
                  {errors.routeDestinationId.message}
                </p>
              )}
            </Grid>
          </fieldset>
        </Grid>
      </Grid>
    </Aux>
  );
};

export default Route;
