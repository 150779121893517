import React from "react";
import Modal from "../Modal";
import { Grid } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import useStyles from "./styles";

const CustomToolbar = ({ selectedData, onDeleteCompanies, path }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Modal data={selectedData} onDelete={onDeleteCompanies} />
      <IconButton
        component={Link}
        to={{ pathname: path, state: { company: selectedData } }}
      >
        <EditIcon />
      </IconButton>
    </Grid>
  );
};

export default CustomToolbar;
