import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    overflow: "visible",
  },
  responsive: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    with: "100%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "flex-end",
      marginTop: theme.spacing(3),
    },
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "30%",
    [theme.breakpoints.up("md")]: {
      width: "30%",
    },
  },
  progress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  backButton: {
    marginTop: theme.spacing(2),
  },
}));
