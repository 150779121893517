import React, { useState, useEffect, useCallback } from "react";
import Aux from "../../hoc/auxiliar";
import Input from "../react-hook-form/input";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Modal from "../../components/CustomModal";
import Table from "mui-datatables";
import translatedOptionsTable from "../../components/Table/options";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import Location from "../../components/Location";
import { modelsOptions } from "../../helpers/truckModel";
import useStyles, { getMuiTheme } from "./styles";
import {
  Button,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Backdrop,
  DialogActions,
} from "@material-ui/core";
import Select from "../../components/react-hook-form/Select";
import { useForm, FormProvider, useFormState, useWatch } from "react-hook-form";
import schemaValidation from "../../helpers/validations/yup/companyValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { fetchCategoriesVehicle } from "../../helpers/truckModel";
import { COLUMNS } from "./config/tableColumns";
import { cnpjMask, phoneMask } from "../../helpers/masks";

const Company = ({ onCancel, onSubmit, editData }) => {
  const classes = useStyles();

  const initialState = {
    category: "",
    cnpj: editData ? editData.cnpj : "",
    companyName: editData ? editData.name : "",
    consumption: "",
    city: editData ? editData.cityId : "",
    email1: editData ? editData.email1 : "",
    email2: editData ? editData.email2 : "",
    model: "",
    phone1: editData ? editData.phone1 : "",
    phone2: editData ? editData.phone2 : "",
    region: editData ? editData.region : "",
    quantity: "",
    testDriveResponsible1: editData ? editData.testDriveResponsible1 : "",
    testDriveResponsible2: editData ? editData.testDriveResponsible2 : "",
    state: editData ? editData.state : "",
  };

  const methods = useForm({
    defaultValues: initialState,
    resolver: yupResolver(schemaValidation),
  });
  const { control, setValue, handleSubmit } = methods;
  const { isSubmitSuccessful, errors } = useFormState({
    control: methods.control,
  });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [fleets, setFleets] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const category = useWatch({ control, name: "category" });
  const city = useWatch({ control, name: "city" });
  const cnpj = useWatch({ control, name: "cnpj" });
  const companyName = useWatch({ control, name: "companyName" });
  const consumption = useWatch({ control, name: "consumption" });
  const model = useWatch({ control, name: "model" });
  const phone1 = useWatch({ control, name: "phone1" });
  const phone2 = useWatch({ control, name: "phone2" });
  const quantity = useWatch({ control, name: "quantity" });
  const region = useWatch({ control, name: "region" });
  const state = useWatch({ control, name: "state" });

  const showBackdrop = useCallback((value) => setOpenBackdrop(value), []);

  const onConfirmModalHandler = () => {
    setDataTable((prevState) => [
      ...prevState,
      {
        modelLabel: model.label,
        categoryLabel: category.label,
        consumption,
        model,
        quantity,
        category,
      },
    ]);
    setFleets((prevState) => [
      ...prevState,
      {
        isNewFleet: true,
        modelLabel: model.label,
        categoryLabel: category.label,
        consumption,
        model,
        quantity,
        category,
      },
    ]);
    setModalOpen(false);
  };
  //Padronização para primeira letra da empresa ser maiuscula.
  useEffect(() => {
    if (companyName) {
      const capitalizeCompanyName =
        companyName.charAt(0).toUpperCase() + companyName.slice(1);
      setValue("companyName", capitalizeCompanyName);
    }
  }, [companyName, setValue]);

  useEffect(() => {
    setValue("cnpj", cnpjMask(cnpj));
  }, [cnpj, setValue]);

  useEffect(() => {
    setValue("phone1", phoneMask(phone1));
  }, [phone1, setValue]);

  useEffect(() => {
    setValue("phone2", phoneMask(phone2));
  }, [phone2, setValue]);

  //Altera as opcoes de categoria conforme modelo, e reseta valor de categoria a cada mudança de modelo.
  useEffect(() => {
    if (model) {
      const options = fetchCategoriesVehicle(model.value);
      setCategoryOptions(options);
      setValue("category", "");
    }
  }, [model, setValue]);

  useEffect(() => {
    setValue("state", "");
    setValue("city", "");
  }, [region, setValue]);

  useEffect(() => {
    setValue("city", "");
  }, [state, setValue]);

  useEffect(() => {
    if (editData) {
      setValue("state", state);
      setValue("city", city);
      setDataTable(editData.fleet);
      setFleets(editData.fleet);
    }
  }, [editData, setValue]);
  return (
    <Aux>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card style={{ overflow: "visible", marginBottom: 30 }}>
        <CardContent>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit((data) => {
                onSubmit({
                  ...data,
                  id: editData ? editData.id : "",
                  fleets: fleets,
                });
              })}
            >
              <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Input
                    disabled={isSubmitSuccessful}
                    error={errors.companyName ? true : false}
                    fullWidth
                    label="Nome da empresa"
                    helperText={errors.companyName?.message}
                    name="companyName"
                    size="small"
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Input
                    disabled={isSubmitSuccessful}
                    fullWidth
                    error={errors.cnpj ? true : false}
                    label="CNPJ"
                    helperText={errors.cnpj?.message}
                    name="cnpj"
                    size="small"
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <Input
                    disabled={isSubmitSuccessful}
                    error={errors.testDriveResponsible1 ? true : false}
                    fullWidth
                    label="Contato 1"
                    helperText={errors.testDriveResponsible1?.message}
                    name="testDriveResponsible1"
                    size="small"
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <Input
                    disabled={isSubmitSuccessful}
                    error={errors.email1 ? true : false}
                    fullWidth
                    label="Email"
                    helperText={errors.email1?.message}
                    name="email1"
                    placeholder="email@example.com"
                    size="small"
                    type="email"
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <Input
                    disabled={isSubmitSuccessful}
                    error={errors.phone1 ? true : false}
                    fullWidth
                    label="Celular"
                    helperText={errors.phone1?.message}
                    name="phone1"
                    size="small"
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <Input
                    disabled={isSubmitSuccessful}
                    fullWidth
                    label="Contato 2"
                    name="testDriveResponsible2"
                    size="small"
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <Input
                    disabled={isSubmitSuccessful}
                    fullWidth
                    label="Email"
                    name="email2"
                    placeholder="email@example.com"
                    size="small"
                    type="email"
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <Input
                    disabled={isSubmitSuccessful}
                    fullWidth
                    label="Celular"
                    name="phone2"
                    size="small"
                  />
                </Grid>

                <Location onLoadingCities={showBackdrop} />
              </Grid>

              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.table}
              >
                <MuiThemeProvider theme={getMuiTheme()}>
                  <Table
                    data={dataTable}
                    title="DADOS DA FROTA"
                    columns={COLUMNS}
                    options={{
                      customToolbar: () => (
                        <IconButton
                          onClick={() => setModalOpen(true)}
                          disabled={isSubmitSuccessful}
                        >
                          <Add />
                        </IconButton>
                      ),
                      ...translatedOptionsTable,
                      search: false,
                      print: false,
                      viewColumns: false,
                      download: false,
                      selectableRows: "single",
                      filter: false,
                      onRowsDelete: (deleted) => {
                        setDataTable((prevState) =>
                          prevState.filter(
                            (data, index) =>
                              index !== deleted.data[0].dataIndex,
                          ),
                        );

                        setFleets((prevState) =>
                          prevState.map((data, index) => {
                            if (index === deleted.data[0].dataIndex) {
                              return {
                                ...data,
                                delete: true,
                              };
                            }
                            return data;
                          }),
                        );
                      },
                    }}
                  />
                </MuiThemeProvider>
                <Modal
                  isOpen={modalOpen}
                  handleClose={() => setModalOpen(false)}
                  maxWidth="md"
                >
                  <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                      <Select
                        label="Marca"
                        name="model"
                        options={modelsOptions}
                        placeholder="SELECIONE UMA OPÇÃO"
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                      <Select
                        label="Categoria"
                        isDisabled={!model}
                        name="category"
                        options={categoryOptions}
                        placeholder="SELECIONE UMA OPÇÃO"
                      />
                    </Grid>

                    <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                      <Input
                        disabled={isSubmitSuccessful}
                        fullWidth
                        label="Quantidade"
                        name="quantity"
                        size="small"
                        type="number"
                      />
                    </Grid>

                    <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                      <Input
                        disabled={isSubmitSuccessful}
                        label="Média KM/L"
                        fullWidth
                        name="consumption"
                        size="small"
                        type="number"
                      />
                    </Grid>
                  </Grid>

                  <DialogActions>
                    <Button onClick={() => setModalOpen(false)} color="primary">
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      autoFocus
                      onClick={onConfirmModalHandler}
                    >
                      Confirmar
                    </Button>
                  </DialogActions>
                </Modal>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="flex-end"
                spacing={3}
                className={classes.buttonGroup}
              >
                <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={onCancel}
                    disabled={isSubmitSuccessful}
                  >
                    CANCELAR
                  </Button>
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disabled={isSubmitSuccessful}
                  >
                    SALVAR
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </CardContent>
      </Card>
    </Aux>
  );
};

export default Company;
