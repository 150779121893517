import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import { useController, useFormContext } from "react-hook-form";
import { ptBR } from 'date-fns/locale'

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";

// Styles
import useStyles from "./styles";
// import style from "react-syntax-highlighter/dist/esm/styles/hljs/agate";



const DatePicker = ({ name, label, control, readOnly, ...rest }) => {
  const classes = useStyles();
  const { control: contextControl } = useFormContext()
  const { field: { ref, ...inputProps } } = useController({
    name,
    control: control ?? contextControl
  });

  return (
    <>
      <div className={classes.root}>
        <InputLabel
          className={classes.label}
          shrink
          id="demo-simple-select-placeholder-label-label"
        >
          {label}
        </InputLabel>
      </div>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <KeyboardDatePicker
          {...rest}
          {...inputProps}
          inputRef={ref}
          inputVariant="outlined"
          size="small"
          format="dd/MM/yyyy"
          fullWidth
          inputProps={{ style: { height: 19, padding: 10 } }}
        />
      </MuiPickersUtilsProvider>
    </>
  );
}

export default DatePicker