import { orderByName } from "../helpers/functions";
import api from "./api";

export const getAllCities = async (idUf) => {
  try {
    const resp = await api.get("v1/cities", {
      uf: idUf,
      limit: 1000,
    });
    const orderCitiesByName = orderByName(resp.data.city);
    return orderCitiesByName;
  } catch (err) {
    return err;
  }
};

export const getCity = async (cityID) => {
  try {
    const resp = await api.get(`v1/cities/${cityID}`);
    return resp;
  } catch (err) {
    return err;
  }
};
