import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    marginTop: 0,
    paddingTop: 6,
    backgroundColor: "ffffff",
    borderColor:"#cccccc",
    borderWidth: 1,
    fontSize: 0.875,
  },
  label: {
    paddingBottom: 0,
  }
}));
