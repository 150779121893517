import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
    width: 170,
  },
}));
