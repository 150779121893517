import api from "./api";

export const getAllUsers = async () => {
  try {
    const resp = await api.get("/v1/users");
    return resp;
  } catch (err) {
    return err;
  }
};

export const createUser = async (data) => {
  try {
    const resp = await api.post("/v1/users", data);
    return resp;
  } catch (err) {
    return err;
  }
};
